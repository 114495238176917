import { useState } from "react";
import classes from "./Element.module.scss";
import { TiEdit } from "react-icons/ti";
import { AiTwotoneDelete } from "react-icons/ai";
import ElementField from "./ElementField/ElementField";

export default function Element({
  element,
  number,
  sectionNumber,
  elementNumber,
  saveElement,
  saveElementField,
  discussionForm,
  setDiscussionForm,
  setResponse,
  noEdit
}) {
  const [editElement, setEditElement] = useState(false);
  const [editedElement, setEditedElement] = useState(element);
  const [deleteElement, setDeleteElement] = useState(false);
  const [addElementField, setAddElementField] = useState(false);
  const [newElementField, setNewElementField] = useState({ name: "", type: "" });
  const elementTypes = [
    { type: "text", name: "テキスト" },
    { type: "File", name: "ファイル" },
    { type: "table", name: "表形式" },
  ];
  return (
    <div className={classes.elementWrapper}>
      <div className={classes.element}>
        <div className={classes.name}>
          {number || ""} {element.name}
          {noEdit?"":<><span
            onClick={() => {
              setEditElement(true);
            }}
          >
            <TiEdit />
          </span>
          <span
            className={classes.delete}
            onClick={() => {
              setDeleteElement(true);
            }}
          >
            <AiTwotoneDelete />
          </span></>}
        </div>
      </div>
      {element?.fields?.map((field, i) => (
        <ElementField
          field={field}
          number={elementTypes.filter((el) => el.type === field.type)[0].name}
          key={field.name}
          saveElementField={saveElementField}
          sectionNumber={sectionNumber}
          elementNumber={elementNumber}
          fieldNumber={i}
          discussionForm={discussionForm}
          setDiscussionForm={setDiscussionForm}
          setResponse={setResponse}
          noEdit={noEdit}
        />
      ))}
      {noEdit?"":<div
        className={`${classes.btns} btn`}
        onClick={() => {
          setAddElementField(true);
        }}
      >
        内容追加
      </div>}
      {editElement ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>編集</div>
            <div className={`popupFormGroup`}>
              <label>表題</label>
              <input
                className={`input`}
                type="text"
                value={editedElement.name}
                onChange={(e) => {
                  setEditedElement({ ...editedElement, name: e.target.value });
                }}
              />
            </div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <span
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  saveElement(editedElement, sectionNumber, elementNumber);
                  setEditElement(false);
                }}
              >
                一時保存
              </span>
              <span
                className={`${classes.btns} btn`}
                onClick={() => {
                  setEditElement(false);
                }}
              >
                キャンセル
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {deleteElement ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  discussionForm.sections[sectionNumber].elements.splice(elementNumber, 1);
                  setDiscussionForm({ ...discussionForm });
                  setResponse({ text: "削除されました" });
                  setDeleteElement(false);
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setDeleteElement(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {addElementField ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>内容</div>
            <div className={`popupFormGroup`}>
              <label>タイプ</label>
              <select
                value={newElementField.type}
                onChange={(e) => {
                  if (e.target.value === "table") {
                    setNewElementField({ ...newElementField, type: e.target.value, numberOfColums: 0, colums: [] });
                  } else {
                    setNewElementField({ ...newElementField, type: e.target.value });
                  }
                }}
              >
                <option value="">項目の種類</option>
                {elementTypes.map((type) => (
                  <option value={type.type} key={type.name}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            {newElementField.type === "" || !newElementField.type ? null : (
              <>
                {/*<div className={`popupFormGroup`}>
                  <label>表題</label>
                  <input
                    required
                    className="input"
                    type="text"
                    value={newElementField?.name}
                    onChange={(e) => {
                      setNewElementField({ ...newElementField, name: e.target.value });
                    }}
                  />
                </div>*/}
                {newElementField.type === "table" ? (
                  <>
                    <div className={`popupFormGroup`}>
                      <label>列数</label>
                      <input
                        min="1"
                        max="9"
                        required
                        className="input"
                        type="number"
                        value={newElementField?.numberOfColums}
                        onChange={(e) => {
                          let colums = [];
                          for (let i = 0; i < (e.target.value > 9 ? 9 : e.target.value); i++) {
                            colums.push({ name: "", file: false });
                          }
                          setNewElementField({ ...newElementField, numberOfColums: e.target.value, colums });
                        }}
                      />
                    </div>
                    {newElementField.numberOfColums
                      ? newElementField.colums.map((el, i) => {
                          return (
                            <>
                              <div className={`popupFormGroup`}>
                                <label> 列名</label>
                                <input
                                  required
                                  className="input"
                                  type="text"
                                  value={el.name}
                                  onChange={(e) => {
                                    let colums = [...newElementField.colums];
                                    colums[i].name = e.target.value;
                                    setNewElementField({ ...newElementField, colums });
                                  }}
                                />
                              </div>
                              <div className={`popupFormGroup`}>
                                <label> ファイル添付列にする</label>
                                <input
                                  required
                                  className="input"
                                  type="checkbox"
                                  checked={el.file}
                                  onChange={(e) => {
                                    let colums = [...newElementField.colums];
                                    colums[i].file = !el.file;
                                    setNewElementField({ ...newElementField, colums });
                                  }}
                                />
                              </div>
                              <div className={`popupFormGroup`}>
                                <label> リンク列にする</label>
                                <input
                                  required
                                  className="input"
                                  type="checkbox"
                                  checked={el.url}
                                  onChange={(e) => {
                                    let colums = [...newElementField.colums];
                                    colums[i].url = !el.url;
                                    setNewElementField({ ...newElementField, colums });
                                  }}
                                />
                              </div>
                            </>
                          );
                        })
                      : null}
                  </>
                ) : null}
              </>
            )}
            <div className={`popupFormGroup popupFormGroupBtn ${newElementField.type?"":classes.oneBtn}`}>
              {newElementField.type ? (
                <div
                  className={`${classes.btns} btn greenBtn`}
                  onClick={() => {
                    setAddElementField(false);
                    setNewElementField({});
                    element.fields.push(newElementField);
                  }}
                >
                  一時保存
                </div>
              ) : null}
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setAddElementField(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
