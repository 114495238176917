import classes from "./CreateEditUser.module.scss";
import { useContext, useState } from "react";
import ChangePassPopup from "./ChangePassPopup/ChangePassPopup";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../../Store/context";

export default function CreateEditUser() {
  const { fetchUsers, users, changePassword, createEditUser, currentUser, year } = useContext(Context);
  const { id } = useParams();
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let user = +id === 0 ? null : users?.filter((user) => +user.id === +id)[0];
  useEffect(() => {
    setUserForm({
      year: currentYear || "",
      id: user?.id,
      name: user?.name || "",
      email: user?.email || "",
      password: "",
      userRoles: user?.userRoles || {
        admin: false,
        roles: [
          {
            year: currentYear,
            premissions: [false, false, false, false, false, false, false, false, false],
            position: "",
          },
        ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const [currentYear, setCurrentYear] = useState(
    currentUser.roles.filter((el) => +el.year === +new Date().getFullYear() && el.premissions[2])[0] ||
      currentUser.isAdmin
      ? new Date().getFullYear().toString()
      : currentUser.roles.filter((el) => el.premissions[2])[0]?.year
  );
  let years = [];
  if (currentUser.isAdmin) {
    for (let i = 2022; i < +new Date().getFullYear() + 10; i++) {
      years.push(i);
    }
  } else {
    years = currentUser.roles.filter((el) => el.premissions[2]).map((el) => el.year);
  }
  const changeYear = (e) => {
    setCurrentYear(e.target.value);
    let yearObj = userForm?.userRoles?.roles.filter((el) => +el.year === +e.target.value)[0];
    if (!yearObj) {
      let temp = userForm?.userRoles?.roles;
      temp.push({
        year: e.target.value,
        premissions: [false, false, false, false, false, false, false, false, false],
        position: "メンバー",
      });
      setPremissionTemplate("メンバー");
      setUserForm({ ...userForm, userRoles: { ...userForm.userRoles, roles: temp }, year: e.target.value });
    }
  };
  const [userForm, setUserForm] = useState({
    year: currentYear || "",
    id: user?.id,
    name: user?.name || "",
    email: user?.email || "",
    password: "",
    userRoles: user?.userRoles || {
      admin: false,
      roles: [
        {
          year: currentYear,
          premissions: [false, false, false, false, false, false, false, false, false],
          position: "全てチェック外す",
        },
      ],
    },
  });
  const onSubmit = (e) => {
    e.preventDefault();
    if (nextYearDirector) {
      let temp = userForm?.userRoles?.roles;
      temp.push({
        year: new Date().getFullYear() + 1,
        premissions: [true, true, true, true, true, true, true, true, true],
        position: "理事長",
      });
      setUserForm({ ...userForm, userRoles: { ...userForm.userRoles, roles: temp }, year: e.target.value });
    }
    createEditUser(userForm);
  };
  const [showPopup, setShowPopup] = useState(false);
  let premissionTemplates = [
    { position: "", premissions: [false, false, false, false, false, false, false, false, false] },
    { position: "全てチェック", premissions: [true, true, true, true, true, true, true, true, true] },
    { position: "会議・議案の作成、編集、削除のみ", premissions: [false, false, true, true, true, true, true, true, true] },
    { position: "会議・議案の作成、編集のみ", premissions: [false, false, true, true, true, false, true, true, false] },
    { position: "全てチェック外す", premissions: [false, false, false, false, false, false, false, false, false] },
  ];
  const [premissionTemplate, setPremissionTemplate] = useState("")
  const setPremissionTemplates = (e) => {
    let currentRoles = userForm?.userRoles?.roles;
    currentRoles = currentRoles.map((el) => {
      if (+el.year === +currentYear) {
        el.premissions = premissionTemplates.filter((el) => el.position === e.target.value)[0].premissions;
        return el;
      }
      return el;
    });
    setPremissionTemplate(e.target.value);
    setUserForm({ ...userForm, userRoles: { ...userForm.userRoles, roles: currentRoles } });
  };
  let premissions = [
    "次年度理事長の作成",
    "通年資料の編集",
    "会員の新規、編集、削除",
    "会議の作成",
    "会議の編集",
    "会議の削除",
    "議案の作成",
    "議案の編集",
    "議案の削除",
  ];
  //TODO select by <Select/>
  const setPremission = (premission) => {
    let currentRoles = userForm?.userRoles?.roles;
    console.log(currentRoles)
    let currentPremissions = currentRoles.filter((el) => +el.year === +currentYear)[0].premissions;
    currentPremissions[premission] = !currentPremissions[premission];
    currentRoles = currentRoles.map((el) => {
      if (el.year === currentYear) {
        el.premissions = currentPremissions;
        return el;
      }
      return el;
    });
    setUserForm({ ...userForm, userRoles: { ...userForm.userRoles, roles: currentRoles } });
  };
  let hasNextYearDirector = false;
  let director =
    currentUser.roles.filter((el) => +el.year === new Date().getFullYear())[0]?.premissions[0] ||
    currentUser.roles.filter((el) => +el.year === new Date().getFullYear())[0]?.premissions[1] ||
    false;
  users.forEach((el) =>
    el.userRoles.roles.forEach((el) => {
      if (+el.year === new Date().getFullYear() + 1 && el.position === "理事長") {
        hasNextYearDirector = true;
      }
    })
  );
  const [nextYearDirector, setNextYearDirector] = useState(false);
  return (
    <div className={classes.wrapper}>
      <form onSubmit={onSubmit}>
        <div className={classes.formGroup}>
          <label>名前</label>
          <input
            required
            className="input"
            type="text"
            value={userForm.name}
            onChange={(e) => {
              setUserForm({ ...userForm, name: e.target.value });
            }}
          />
        </div>
        {!user?.id ? (
          <div className={classes.formGroup}>
            <label>パスワード</label>
            <input
              required
              className="input"
              type="text"
              value={userForm.password}
              onChange={(e) => {
                setUserForm({ ...userForm, password: e.target.value });
              }}
            />
          </div>
        ) : (
          <div className={classes.formGroup}>
            <div
              className={`${classes.btns} btn`}
              onClick={() => {
                setShowPopup(true);
              }}
            >
              パスワード再発行
            </div>
          </div>
        )}
        <div className={classes.formGroup}>
          <label>電子メール</label>
          <input
            required
            className="input"
            type="email"
            value={userForm.email}
            onChange={(e) => {
              setUserForm({ ...userForm, email: e.target.value });
            }}
          />
        </div>
        {currentUser.isAdmin ? (
          <div className={classes.formGroup}>
            <label>システム管理者</label>
            <input
              type="checkbox"
              checked={userForm.userRoles.admin}
              onChange={() => {}}
              onClick={() => {
                setUserForm({ ...userForm, userRoles: { ...userForm.userRoles, admin: !userForm.userRoles.admin } });
              }}
            />
          </div>
        ) : null}
        {!hasNextYearDirector && (director || currentUser.isAdmin) ? (
          <div className={classes.formGroup}>
            <label>次年度役員</label>
            <input
              type="checkbox"
              checked={nextYearDirector}
              onChange={() => {}}
              onClick={() => {
                setNextYearDirector(!nextYearDirector);
              }}
            />
          </div>
        ) : null}
        {userForm.userRoles.admin || nextYearDirector ? null : (
          <>
            {years.length === 1 ? (
              <div className={classes.formGroup}>
                <label>管理年度</label>
                <div>{years[0]}年</div>
              </div>
            ) : (
              <div className={classes.formGroup}>
                <label>管理年度</label>
                <div className={classes.year}>
                  <select className={classes.tabs} value={currentYear} onChange={changeYear}>
                    {years.map((el) => (
                      <option value={el} key={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                  年
                </div>
              </div>
            )}
            <div className={classes.formGroup}>
              <label>役割</label>
              <input
                required
                className="input"
                type="text"
                value={userForm?.userRoles?.roles?.filter(el=>+el.year === +currentYear)[0]?.position}
                onChange={(e) => {
                  userForm?.userRoles?.roles.map((el) => {
                    if (+el.year === +currentYear) {
                      el.position = e.target.value;
                      return el;
                    }
                    return el;
                  });
                  setUserForm({ ...userForm });
                }}
              />
            </div>
            {currentUser.roles.filter((el) => +el.year === +currentYear)[0]?.premissions[2] || currentUser.isAdmin ? (
              <>
                <div className={classes.formGroup}>
                  <label>権限のテンプレート</label>
                  <select
                    value={premissionTemplate}
                    onChange={setPremissionTemplates}
                  >
                    {premissionTemplates.map((position) => (
                      <option value={position.position} key={position.position}>
                        {position.position}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={classes.formGroup}>
                  <label>権限</label>
                  <div>
                    {premissions.map((premission, i) => {
                      return (
                        <div className={classes.checkboxGroup} key={premission}>
                          <label>{premission}</label>
                          <input
                            type="checkbox"
                            checked={
                              userForm?.userRoles?.roles.filter((el) => +el.year === +currentYear)[0]?.premissions[i]
                            }
                            onChange={() => {}}
                            onClick={() => {
                              setPremission(i);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}
        <div className={classes.formGroup}>
          <button className={`${classes.btns} btn greenBtn`}>更新</button>
          <Link to="/admin/users">
            <div className={`${classes.btns} btn`}>戻る</div>
          </Link>
        </div>
      </form>
      {showPopup ? (
        <ChangePassPopup changePassword={changePassword} setShowPopup={setShowPopup} id={user?.id} year={year} />
      ) : (
        ""
      )}
    </div>
  );
}
