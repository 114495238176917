import classes from "./File.module.scss";
import Editor from "../Editor/Editor";
import Compare from "../Compare/Compare";
import { useState } from "react";
import { TiEdit } from "react-icons/ti";
import { AiTwotoneDelete } from "react-icons/ai";

function File({
  editMode,
  current,
  prev,
  //setTextElementFile,
  setElementFile,
  params,
  setFiles,
  files,
  history,
  setPDFViewer,
  setEdited,
  phase,
}) {
  const [addFile, setAddFile] = useState(false);
  const [newFile, setNewFile] = useState({ name: "", fileName: "", file: {} });
  const [editFile, setEditFile] = useState(false);
  const [deleteFile, setDeleteFile] = useState(false);

  return (
    <div className={classes.fileField}>
      {editMode ? (
        <>
          {current?.files?.map((file, i) => {
            return (
              <div key={i} className={classes.fileWrapper}>
                <div className={(file.file.currentHistory === history && history > 0 && file.file.currentPhase === phase) ? classes.fontRed : ""}>
                <Compare
                  className={classes.file}
                  prev={prev?.files ? prev?.files[i]?.name : undefined}
                  current={file?.name || "該当無し"}
                />
                </div>
                <span
                  onClick={() => {
                    setEditFile(i);
                    setNewFile(current?.files[i]);
                  }}
                >
                  <TiEdit />
                </span>
                <span
                  className={classes.delete}
                  onClick={() => {
                    setDeleteFile(i);
                  }}
                >
                  <AiTwotoneDelete />
                </span>
              </div>
            );
          })}
          <button
            className={`${classes.delete} btn`}
            onClick={() => {
              setAddFile(true);
            }}
          >
            ファイル選択
          </button>
        </>
      ) : (
        <div className={classes.itemValue}>
          <span>
            {current?.files?.map((file, i) => {
              return (
                <div
                  className={classes.file}
                  key={i}
                  onClick={() => {
                    setPDFViewer(file?.fileName);
                  }}
                >
                  <div className={(file.file.currentHistory === history && history > 0 && file.file.currentPhase === phase) ? classes.fontRed : ""}>
                    <Compare prev={prev?.files ? prev?.files[i]?.name : undefined} current={file?.name || "該当無し"} />
                  </div>
                </div>
              );
            })}
          </span>
        </div>
      )}
      {addFile ? (
        <div className={`popupWrapper ${classes.popupWrapper}`}>
          <div className="popup">
            <div className={`popupTitle`}></div>
            <div className={`popupFormGroup`}>
              <label>ファイル表題</label>
              <input
                placeholder="ファイル表題"
                required
                className="input"
                type="text"
                value={newFile.name}
                onChange={(e) => {
                  setNewFile({ ...newFile, name: e.target.value });
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label>ファイル選択</label>
              <input
                required
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  setNewFile({
                    ...newFile,
                    fileName: history + "_" + e.target.files[0]?.name,
                    file: {
                      file: e.target.files[0],
                      fileSrc: URL.createObjectURL(e.target.files[0]),
                      number: files.length,
                      upDate: undefined,
                      currentHistory: history,
                      currentPhase: phase,
                    },
                  });
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <button
                className={`${classes.delete} btn greenBtn`}
                onClick={() => {
                  setEdited(true);
                  setAddFile(false);
                  setElementFile(newFile, params);
                  setFiles([...files, newFile.file]);
                  setNewFile({ name: "", fileName: "", file: {} });
                }}
              >
                一時保存
              </button>
              <div
                className={`${classes.btn} btn`}
                onClick={() => {
                  setAddFile(false);
                  setNewFile({ name: "", fileName: "", file: {} });
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {editFile || editFile === 0 ? (
        <div className={`popupWrapper ${classes.popupWrapper}`}>
          <div className="popup">
            <div className={`popupTitle`}></div>
            <div className={`popupFormGroup`}>
              <label>ファイル表題</label>
              <input
                placeholder="ファイル表題"
                required
                className="input"
                type="text"
                value={newFile.name}
                onChange={(e) => {
                  setNewFile({ ...newFile, name: e.target.value });
                }}
              />
            </div>
            <div className={`popupFormGroup`}>
              <label>現保存ファイル</label>
              <div className={classes.fileName}>{newFile.fileName}</div>
            </div>
            <div className={`popupFormGroup`}>
              <label>ファイル選択</label>
              <input
                required
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  setNewFile({
                    ...newFile,
                    fileName: history + "_" + e.target.files[0]?.name,
                    file: {
                      file: e.target.files[0],
                      fileSrc: URL.createObjectURL(e.target.files[0]),
                      number: files.length,
                      upDate: new Date().toString(),
                      currentHistory: history,
                      currentPhase: phase,
                    },
                  });
                }}
              />
            </div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <button
                className={`${classes.btn} btn greenBtn`}
                onClick={() => {
                  setEdited(true);
                  setEditFile(false);
                  setElementFile(newFile, params, false, editFile);
                  setFiles([...files, newFile.file]);
                  setNewFile({ name: "", fileName: "", file: {} });
                }}
              >
                一時保存
              </button>
              <div
                className={`${classes.btn} btn`}
                onClick={() => {
                  setEditFile(false);
                  setNewFile({ name: "", fileName: "", file: {} });
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {deleteFile || deleteFile === 0 ? (
        <div className={`popupWrapper ${classes.popupWrapper}`}>
          <div className="popup">
            <div className={`popupTitle`}>削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <button
                className={`${classes.btn} btn greenBtn`}
                onClick={() => {
                  let file = current?.files[deleteFile];
                  setDeleteFile(false);
                  setEdited(true);
                  setElementFile(file?.fileName, params, true);
                }}
              >
                実行
              </button>
              <div
                className={`${classes.btn} btn`}
                onClick={() => {
                  setDeleteFile(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default File;
