import classes from "./Table.module.scss";
import Compare from "../Compare/Compare";
import { useEffect, useState } from "react";
import Col from "./Col/Col";
import { TiEdit } from "react-icons/ti";
import { AiTwotoneDelete } from "react-icons/ai";

function Table({
  editMode,
  current,
  prev,
  setTableRow,
  params,
  setFiles,
  files,
  history,
  setEdited,
  setPDFViewer,
  phase,
  elementIsInEdit,
  setElementIsInEdit,
}) {
  useEffect(() => {
    if (!editMode) {
      setAddRow(false);
      setEditRow(current.values?.map((el) => {
        return false;
      }) || []);
    }
  }, [editMode]);

  const [addRow, setAddRow] = useState(false);
  const [newRow, setNewRow] = useState({
    colums: current.colums?.map((el) => {
      return {};
    }),
  });
  const [file, setFile] = useState({ name: "", fileName: "", file: {} });
  const [editRow, setEditRow] = useState(
    current.values?.map((el) => {
      return false;
    }) || []
  );
  const [saveRowPopup, setSaveRowPopup] = useState(false);
  const [deleteRow, setDeleteRow] = useState(false);
  const [elementIsInEditPopup, setElementIsInEditPopup] = useState(false);
  return (
    <div className={classes.tableField}>
      <table className={classes.tableElement}>
        <tbody>
          <tr>
            <td className={`${classes.tableHeaders} ${classes.trCount}`}></td>
            {current.colums?.map((column, i) => (
              <td key={column.name + i} className={classes.tableHeaders}>
                {column.name}
              </td>
            ))}
          </tr>
          {current.values?.map((value, i) => (
            <tr key={i}>
              <td className={`${classes.tableHeaders} ${classes.trCount}`}>{i + 1}</td>
              {editRow[i] ? (
                <>
                  {newRow.colums?.map((col, i) => {
                    return (
                      <Col
                        col={col}
                        colNumberInRow={i}
                        current={current}
                        newRow={newRow}
                        setNewRow={setNewRow}
                        setFile={setFile}
                        file={file}
                        files={files}
                        history={history}
                        value={value}
                        phase={phase}
                      />
                    );
                  })}
                  <td>
                    <button
                      className={`${classes.delete} btn greenBtn`}
                      onClick={() => {
                        setEdited(true);
                        editRow[i] = false;
                        setEditRow([...editRow]);
                        setElementIsInEdit(false);
                        setFiles([...files, file.file]);
                        setFile({ name: "", fileName: "", file: {} });
                        setTableRow(newRow, params, false, i);
                        setNewRow({
                          colums: current.colums?.map((el) => {
                            return {};
                          }),
                        });
                      }}
                    >
                      一時保存
                    </button>
                    <button
                      className={`${classes.delete} btn`}
                      onClick={() => {
                        editRow[i] = false;
                        setEditRow([...editRow]);
                        setElementIsInEdit(false);
                        setNewRow({
                          colums: current.colums?.map((el) => {
                            return {};
                          }),
                        });
                      }}
                    >
                      キャンセル
                    </button>
                  </td>
                </>
              ) : (
                value.colums?.map((column, j) => {
                  return (
                    <td key={j}>
                      {column.fileName || column.urlValue || column.urlFileTitle ? (
                        <div className={column.currentHistory === history && column.currentHistory > 0 && column.currentPhase === phase ? classes.fontRed : ""}>
                          {editMode ? (
                            <>
                              <div>
                                <Compare
                                  prev={prev?.values ? prev?.values[i]?.colums[j]?.urlFileTitle : undefined}
                                  current={column.urlFileTitle}
                                />
                                ({column.fileName || column.urlValue})
                              </div>
                            </>
                          ) : column.fileName ? (
                            <div
                              className={classes.pdf}
                              onClick={() => {
                                setPDFViewer(column.fileName);
                              }}
                            >
                              <Compare
                                prev={prev?.values ? prev?.values[i]?.colums[j]?.urlFileTitle : undefined}
                                current={column.urlFileTitle}
                              />
                            </div>
                          ) : column.urlValue ? (
                            <a className={classes.urlValue} href={column.urlValue} target="_blank" rel="noreferrer">
                              <Compare
                                prev={prev?.values ? prev?.values[i]?.colums[j]?.urlFileTitle : undefined}
                                current={column.urlFileTitle}
                              />
                            </a>
                          ) : (
                            <Compare
                              prev={prev?.values ? prev?.values[i]?.colums[j]?.urlFileTitle : undefined}
                              current={column.urlFileTitle}
                            />
                          )}
                        </div>
                      ) : (
                        <div className={column.currentHistory === history && column.currentHistory > 0 && column.currentPhase === phase ? classes.fontRed : ""}>
                          <Compare
                            prev={prev?.values ? prev?.values[i]?.colums[j]?.fieldValue : undefined}
                            current={column.fieldValue}
                          />
                        </div>
                      )}
                    </td>
                  );
                })
              )}
              {editMode ? (
                <>
                  {editRow[i] ? (
                    <></>
                  ) : (
                    <td className={classes.editSvg}>
                      <TiEdit
                        onClick={() => {
                          if (editRow.filter((el) => el)[0] || addRow) {
                            setSaveRowPopup(true);
                          } else if (elementIsInEdit) {
                            setElementIsInEditPopup(true);
                          } else {
                            editRow[i] = true;
                            setElementIsInEdit(true);
                            setEditRow([...editRow]);
                            setNewRow(JSON.parse(JSON.stringify(value)));
                          }
                        }}
                      />
                    </td>
                  )}
                  <td className={classes.deleteSvg}>
                    <AiTwotoneDelete
                      onClick={() => {
                        if (editRow.filter((el) => el)[0] || addRow) {
                          setSaveRowPopup(true);
                        } else if (elementIsInEdit) {
                          setElementIsInEditPopup(true);
                        } else {
                          setDeleteRow(i);
                        }
                      }}
                    />
                  </td>
                </>
              ) : null}
            </tr>
          ))}
          {editMode ? (
            <tr>
              {addRow ? (
                <>
                  <td className={classes.tableHeaders}>{(current.values?.length || 0) + 1}</td>
                  {newRow.colums?.map((col, i) => {
                    return (
                      <Col
                        col={col}
                        colNumberInRow={i}
                        current={current}
                        newRow={newRow}
                        setNewRow={setNewRow}
                        setFile={setFile}
                        file={file}
                        files={files}
                        history={history}
                        phase={phase}
                      />
                    );
                  })}
                  <td>
                    <button
                      className={`${classes.delete} btn greenBtn greenBtn`}
                      onClick={() => {
                        setEdited(true);
                        setAddRow(false);
                        setElementIsInEdit(false);
                        setFiles([...files, file.file]);
                        setFile({ name: "", fileName: "", file: {} });
                        setTableRow(newRow, params);
                        setNewRow({
                          colums: current.colums?.map((el) => {
                            return {};
                          }),
                        });
                      }}
                    >
                      一時保存
                    </button>
                    <button
                      className={`${classes.delete} btn`}
                      onClick={() => {
                        setAddRow(false);
                        setElementIsInEdit(false);
                        setNewRow({
                          colums: current.colums?.map((el) => {
                            return {};
                          }),
                        });
                      }}
                    >
                      キャンセル
                    </button>
                  </td>
                </>
              ) : (
                <td colSpan={+current.numberOfColums + 3}>
                  <button
                    className={`${classes.delete} btn`}
                    onClick={() => {
                      if (editRow?.filter((el) => el)[0]) {
                        setSaveRowPopup(true);
                      } else if (elementIsInEdit) {
                        setElementIsInEditPopup(true);
                      } else {
                        setAddRow(true);
                        setElementIsInEdit(true);
                        setNewRow({
                          colums: current.colums?.map((el) => {
                            return {};
                          }),
                        });
                      }
                    }}
                  >
                    行追加
                  </button>
                </td>
              )}
            </tr>
          ) : null}
        </tbody>
      </table>
      {saveRowPopup ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>編集中の行があります</div>
            <div className={``}>
              <div
                className={`${classes.btn} btn`}
                onClick={() => {
                  setSaveRowPopup(false);
                }}
              >
                閉じる
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {elementIsInEditPopup ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>編集中の表形式があります</div>
            <div className={``}>
              <div
                className={`${classes.btn} btn`}
                onClick={() => {
                  setElementIsInEditPopup(false);
                }}
              >
                閉じる
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {deleteRow || deleteRow === 0 ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>行を削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <button
                className={`${classes.btn} btn greenBtn`}
                onClick={() => {
                  setEdited(true);
                  current.values.splice(deleteRow, 1);
                  setTableRow(newRow, params, true);
                  setDeleteRow(false);
                }}
              >
                実行
              </button>
              <div
                className={`${classes.btn} btn`}
                onClick={() => {
                  setDeleteRow(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Table;
