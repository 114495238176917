import { useContext, useEffect, useState } from "react";
import Table from "../components/Admin/Table/Table";
import Filters from "../components/Admin/Filters/Filters";
import { Context } from "../Store/context";
import Sidebar from "../components/Admin/Sidebar/Sidebar";

function UsersPage() {
  const { fetchUsers, users, currentUser, disableEnable, year } = useContext(Context);
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let cols = ["email", "name", "createdAt", "updatedAt", "active"];
  let jpcols = ["メール", "氏名", "作成日", "更新日", "状態"];
  const [all, setAll] = useState(false);
  const [search, setSearch] = useState("");
  //todo Pagination
  return (
    <div className="adminPages">
      <Sidebar />
      <div className="content">
        <div className="title">会員一覧</div>
        <Filters setAll={setAll} all={all} search={search} setSearch={setSearch}/>
        <Table
          entities={users}
          cols={cols}
          currentUser={currentUser}
          jpcols={jpcols}
          disableEnable={disableEnable}
          year={year}
          all={all}
          search={search}
        />
      </div>
    </div>
  );
}

export default UsersPage;
