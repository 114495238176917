import classes from "./DiscussionMap.module.scss";

function DiscussionMap({ currentDiscussion, myScrollTo }) {
  return (
    <div className={classes.disscussionMap}>
      <span
        onClick={() => {
          myScrollTo(`議案情報${currentDiscussion.history}`);
        }}
      >
        議案情報{" "}
      </span>
      {currentDiscussion?.sections?.map((el) => {
        return (
          <span
            key={el.name}
            onClick={() => {
              myScrollTo(el.name+currentDiscussion.history);
            }}
          >
            / {el.name}{" "}
          </span>
        );
      })}
    </div>
  );
}

export default DiscussionMap;
