import { useContext, useEffect } from "react";
import { Context } from "../../../Store/context";
import classes from "./MeetingPDF.module.scss";

function MeetingPDF({ content,mini }) {
  const { fetchFile, pdf } = useContext(Context);
  useEffect(() => {
    fetchFile(`${content}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);
  return (
    <div className={`${classes.pdf} ${mini?classes.full:""}`}>
      <iframe src={pdf} frameBorder="0" title={content}></iframe>
    </div>
  );
}

export default MeetingPDF;
