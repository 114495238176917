import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Discussion from "../components/Meeting/Discussion/Discussion";
import MeetingContent from "../components/Meeting/MeetingContent/MeetingContent";
import MeetingPDF from "../components/Meeting/MeetingPDF/MeetingPDF";
import MeetingSiderbar from "../components/Meeting/MeetingSidebar/MeetingSidebar";
import NotSavedPopup from "../components/Meeting/NotSavedPopup/NotSavedPopup";
//import CreateEditMeeting from "../components/CreateEdit/CreateEditMeeting/CreateEditMeeting";

import { Context } from "../Store/context";

function MeetingPage() {
  const { fetchMeeting, meeting, currentUser, discussions, fetchDiscussions, fetchUsers, users } = useContext(Context);
  const { id } = useParams();
  const [edited, setEdited] = useState(false);
  const [content, setContent] = useState("content");
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    fetchUsers();
    fetchMeeting(id);
    fetchDiscussions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [PDFViewer, setPDFViewer] = useState("");
  const [notSavedPopupTrigger, setNotSavedPopupTrigger] = useState(false);
  const [mini, setMini] = useState(false);
  return (
    <div className="MeetingPage">
      <MeetingSiderbar
        edited={edited}
        meeting={meeting}
        discussions={discussions}
        setContent={setContent}
        setEditMode={setEditMode}
        setPDFViewer={setPDFViewer}
        setNotSavedPopupTrigger={setNotSavedPopupTrigger}
        mini={mini}
        setMini={setMini}
      />
      {
        {
          content: <MeetingContent meeting={meeting} setContent={setContent} mini={mini} />,
          pdf: <MeetingPDF content={content.name} mini={mini} />,
          discussion: (
            <Discussion
              //historyNumber={historyNumber}
              editMode={editMode}
              setEditMode={setEditMode}
              setContent={setContent}
              setEdited={setEdited}
              edited={edited}
              users={users}
              meeting={meeting}
              currentUser={currentUser}
              //editDiscussion={editDiscussion}
              id={id}
              discussionId={content.id}
              phase={content.phase}
              discussion={
                discussions?.filter((el) => +el.id === content.id)[0]
                  ? discussions?.filter((el) => +el.id === content.id)[0][content.phase]
                  : []
              }
              setPDFViewer={setPDFViewer}
              PDFViewer={PDFViewer}
              setNotSavedPopupTrigger={setNotSavedPopupTrigger}
              mini={mini}
            />
          ),
        }[content.type]
      }
      {notSavedPopupTrigger ? (
        <NotSavedPopup
          setNotSavedPopupTrigger={setNotSavedPopupTrigger}
          setEditMode={setEditMode}
          setContent={setContent}
          setPDFViewer={setPDFViewer}
          notSavedPopupTrigger={notSavedPopupTrigger}
          setEdited={setEdited}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default MeetingPage;
