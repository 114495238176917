import { useState } from "react";
import classes from "./ElementField.module.scss";
import { TiEdit } from "react-icons/ti";
import { AiTwotoneDelete } from "react-icons/ai";

export default function ElementField({
  field,
  number,
  sectionNumber,
  elementNumber,
  fieldNumber,
  saveElementField,
  discussionForm,
  setDiscussionForm,
  setResponse,
  noEdit
}) {
  const [editElement, setEditElement] = useState(false);
  const [editedElement, setEditedElement] = useState(field);
  const [deleteElement, setDeleteElement] = useState(false);
  console.log(noEdit)
  return (
    <>
      <div className={classes.element}>
        <div className={classes.name}>
          <span>{number || ""}</span>
          {field?.type === "table" && !noEdit? (
            <span
              onClick={() => {
                setEditElement(true);
              }}
            >
              <TiEdit />
            </span>
          ) : ""}
          {noEdit?"":<span
            className={classes.delete}
            onClick={() => {
              setDeleteElement(true);
            }}
          >
            <AiTwotoneDelete />
          </span>}
        </div>
      </div>
      <div>
        {field?.type === "table" ? (
          <table>
            <tbody>
              <tr>
                <td>連番行</td>
                {field.colums?.map((el) => (
                  <td key={el.name}>
                    {el.name || "(text column)"}{" "}
                    {`${
                      el.file || el.url
                        ? `(${el.file ? `ファイル${el.url ? "" : ")"}` : ""}${
                            el.url ? (el.file ? ", URL)" : "URL)") : ""
                          }`
                        : ""
                    }`}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        ) : null}
        {editElement ? (
          <div className={`popupWrapper`}>
            <div className="popup">
              <div className={`popupTitle`}>編集</div>
              {/*<div className={`popupFormGroup`}>
                <label>表題</label>
                <input
                  className={`input`}
                  type="text"
                  value={editedElement.name}
                  onChange={(e) => {
                    setEditedElement({ ...editedElement, name: e.target.value });
                  }}
                />
                </div>*/}
              {editedElement.type === "table" ? (
                <>
                  <div className={`popupFormGroup`}>
                    <label>列数</label>
                    <input
                      min="1"
                      max="9"
                      required
                      className="input"
                      type="number"
                      value={editedElement?.numberOfColums}
                      onChange={(e) => {
                        let colums = [...editedElement.colums];
                        if (editedElement?.numberOfColums < e.target.value) {
                          for (let i = editedElement?.numberOfColums; i < e.target.value; i++) {
                            colums.push({ name: "", file: false });
                          }
                        } else {
                          colums.splice(e.target.value);
                        }

                        setEditedElement({ ...editedElement, numberOfColums: e.target.value, colums });
                      }}
                    />
                  </div>
                  {editedElement.numberOfColums
                    ? editedElement.colums.map((el, i) => {
                        return (
                          <>
                            <div className={`popupFormGroup`}>
                              <label> 列名</label>
                              <input
                                required
                                className="input"
                                type="text"
                                value={el.name}
                                onChange={(e) => {
                                  let colums = [...editedElement.colums];
                                  colums[i].name = e.target.value;
                                  setEditedElement({ ...editedElement, colums });
                                }}
                              />
                            </div>
                            <div className={`popupFormGroup`}>
                              <label> ファイル添付列にする</label>
                              <input
                                className="input"
                                type="checkbox"
                                checked={el.file}
                                onChange={(e) => {
                                  let colums = [...editedElement.colums];
                                  colums[i].file = !el.file;
                                  setEditedElement({ ...editedElement, colums });
                                }}
                              />
                            </div>
                            <div className={`popupFormGroup`}>
                              <label> リンク列にする</label>
                              <input
                                className="input"
                                type="checkbox"
                                checked={el.url}
                                onChange={(e) => {
                                  let colums = [...editedElement.colums];
                                  colums[i].url = !el.url;
                                  setEditedElement({ ...editedElement, colums });
                                }}
                              />
                            </div>
                          </>
                        );
                      })
                    : null}
                </>
              ) : null}
              <div className={`popupFormGroup popupFormGroupBtn`}>
                <span
                  className={`${classes.btns} btn greenBtn`}
                  onClick={() => {
                    saveElementField(editedElement, sectionNumber, elementNumber, fieldNumber);
                    setEditElement(false);
                  }}
                >
                  一時保存
                </span>
                <span
                  className={`${classes.btns} btn`}
                  onClick={() => {
                    setEditElement(false);
                  }}
                >
                  キャンセル
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {deleteElement ? (
          <div className={`popupWrapper`}>
            <div className="popup">
              <div className={`popupTitle`}>削除します</div>
              <div className={`popupFormGroup popupFormGroupBtn`}>
                <div
                  className={`${classes.btns} btn greenBtn`}
                  onClick={() => {
                    discussionForm.sections[sectionNumber].elements[elementNumber].fields.splice(fieldNumber, 1);
                    setDiscussionForm({ ...discussionForm });
                    setResponse({ text: "削除されました" });
                    setDeleteElement(false);
                  }}
                >
                  実行
                </div>
                <div
                  className={`${classes.btns} btn`}
                  onClick={() => {
                    setDeleteElement(false);
                  }}
                >
                  キャンセル
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
