import {
    FETCH_USERS,
    SET_AUTH,
    SET_AUTH_ERROR,
    SET_ADMIN,
    SET_CURRENT_USER,
    SET_RESPONSE,
    FETCH_MEETINGS,
    FETCH_DISCUSSIONS,
    FETCH_PDF,
    FETCH_MAIN_DOCUMENTS,
    FETCH_DISCUSSION,
    FETCH_MEETING,
    FETCH_DISCUSSION_BY_PHASE,
    SET_YEAR,
    FETCH_TEMPLATES,
    FETCH_REPORTS,
  } from "./types";
  const handlers = {
    [FETCH_TEMPLATES]: (state, { payload }) => ({
      ...state,
      templates: payload,
    }),
    [FETCH_PDF]: (state, { payload }) => ({
      ...state,
      pdf: payload,
    }),
    [FETCH_DISCUSSION_BY_PHASE]: (state, { payload }) => ({
      ...state,
      discussionByPhase: payload,
    }),
    [FETCH_DISCUSSION]: (state, { payload }) => ({
      ...state,
      discussion: payload,
    }),
    [FETCH_DISCUSSIONS]: (state, { payload }) => ({
      ...state,
      discussions: payload,
    }),
    [FETCH_MEETING]: (state, { payload }) => ({
      ...state,
      meeting: payload,
    }),
    [FETCH_MEETINGS]: (state, { payload }) => ({
      ...state,
      meetings: payload,
    }),
    [FETCH_MAIN_DOCUMENTS]: (state, { payload }) => ({
      ...state,
      mainDocuments: payload,
    }),
    [FETCH_USERS]: (state, { payload }) => ({
      ...state,
      users: payload,
    }),
    [SET_YEAR]: (state, { payload }) => ({
      ...state,
      year: payload,
    }),
    [SET_RESPONSE]: (state, { payload }) => ({
      ...state,
      response: payload,
    }),
    [SET_CURRENT_USER]: (state, { payload }) => ({
      ...state,
      currentUser: payload,
    }),
    [SET_ADMIN]: (state, { payload }) => ({
      ...state,
      admin: payload,
    }),
    [SET_AUTH]: (state, { payload }) => ({
      ...state,
      auth: payload,
    }),
    [SET_AUTH_ERROR]: (state, { payload }) => ({
      ...state,
      loginError: payload,
    }),
    [FETCH_REPORTS]: (state, { payload }) => ({
      ...state,
      reports: payload,
    }),
    DEFAULT: (state) => state,
  };
  
  export const reducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    let x = handle(state, action);
    if (action.type === "SET_CURRENT_ADMIN") {
      //console.log(action)
    }
    return x;
  };
  