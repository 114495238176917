import { useState, useContext } from "react";
import { Context } from "../Store/context";

function AuthPage() {
  const { LogIn, loginError, resetLoginError } = useContext(Context);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const onAuth = (e) => {
    e.preventDefault()
    LogIn(email, password);
  };
  return (
    <div className="AuthPage">
       <img src="/images/JCIlogo.jpg" alt="logo" width="20%" />
      <form onSubmit={onAuth}>
        <div className="wrapper">
          <div className="loginError">{loginError}</div>
          <input
            type="text"
            placeholder="メールアドレス"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              resetLoginError();
            }}
          />
          <input
            type="password"
            placeholder="パスワード"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              resetLoginError();
            }}
          />
          <button className="btn">ログイン</button>
        </div>
      </form>
    </div>
  );
}

export default AuthPage;
