export const SET_AUTH_ERROR = "SET_AUTH_ERROR"
export const SET_AUTH = "SET_AUTH"
export const SET_CURRENT_USER = "SET_CURRENT_USER"
export const SET_ADMIN = "SET_ADMIN"
export const SET_RESPONSE = "SET_RESPONSE"
export const SET_YEAR = "SET_YEAR"
export const FETCH_USERS = "FETCH_USERS"
export const FETCH_MAIN_DOCUMENTS = "FETCH_MAIN_DOCUMENTS"
export const FETCH_MEETINGS = "FETCH_MEETINGS"
export const FETCH_MEETING = "FETCH_MEETING"
export const FETCH_DISCUSSIONS = "FETCH_DISCUSSIONS"
export const FETCH_DISCUSSION = "FETCH_DISCUSSION"
export const FETCH_DISCUSSION_BY_PHASE = "FETCH_DISCUSSION_BY_PHASE"
export const FETCH_TEMPLATES = "FETCH_TEMPLATES"
export const FETCH_PDF = "FETCH_PDF"
export const FETCH_REPORTS = "FETCH_REPORTS"
