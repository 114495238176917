import Sidebar from "../components/Admin/Sidebar/Sidebar";
import CreateEditMeeting from "../components/Admin/Meetings/CreateEditMeeting/CreateEditMeeting";

function CreateEditMeetingPage() {
  return (
    <div className="adminPages">
      <Sidebar/>
      <div className="content">
        <CreateEditMeeting />
      </div>
    </div>
  );
}

export default CreateEditMeetingPage;
