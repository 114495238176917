import CreateEditDiscussion from "../components/Admin/Meetings/CreateEditDiscusson/CreateEditDiscussion";
import Sidebar from "../components/Admin/Sidebar/Sidebar";

function CreateEditDiscussionPage() {
  return (
    <div className="adminPages">
      <Sidebar/>
      <div className="content">
        <CreateEditDiscussion />
      </div>
    </div>
  );
}

export default CreateEditDiscussionPage;
