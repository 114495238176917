import classes from "./MeetingSidebar.module.scss";
import parse from "html-react-parser";
import { useContext, useEffect } from "react";
import { Context } from "../../../Store/context";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";

function MeetingSidebar({
  meeting,
  setContent,
  setEditMode,
  setPDFViewer,
  edited,
  setNotSavedPopupTrigger,
  setMini,
  mini,
}) {
  const { mainDocuments, fetchMainDocuments, fetchReports, reports } = useContext(Context);
  useEffect(() => {
    fetchMainDocuments();
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  meeting?.discussions?.sort((a, b) => a.number - b.number);
  const linkFunction = (editmode, content, pdf) => {
    setEditMode(editmode);
    setContent(content);
    setPDFViewer(pdf);
  };
  return (
    <div className={`${classes.sidebar}  ${mini ? "" : classes.show}`}>
      <div className={`${classes.sidebarWrapper} ${mini ? "" : classes.show}`}>
        <div className={classes.top}>
          <div className={classes.title}>会議情報</div>
          <div className={` ${classes.mainTitle}`}>{meeting.name}</div>
          <div className={` ${classes.mainTitle}`}>{meeting.date}</div>
          <div className={` ${classes.mainTitle}`}>{meeting.place}</div>
          <div className={classes.content}>
            <div
              className={classes.link}
              onClick={() => {
                if (!edited) {
                  linkFunction(
                    false,
                    { type: "pdf", name: `${meeting?.year}/meetings/${meeting?.id}/${meeting?.current}` },
                    ""
                  );
                } else {
                  setNotSavedPopupTrigger({
                    func: linkFunction,
                    params: [
                      false,
                      { type: "pdf", name: `${meeting?.year}/meetings/${meeting?.id}/${meeting?.current}` },
                      "",
                    ],
                  });
                }
              }}
            >
              次第
            </div>
            <div
              className={classes.link}
              onClick={() => {
                if (!edited) {
                  linkFunction(
                    false,
                    { type: "pdf", name: `${meeting?.year}/meetings/${meeting?.id}/${meeting?.previous}` },
                    ""
                  );
                } else {
                  setNotSavedPopupTrigger({
                    func: linkFunction,
                    params: [
                      false,
                      { type: "pdf", name: `${meeting?.year}/meetings/${meeting?.id}/${meeting?.previous}` },
                      "",
                    ],
                  });
                }
              }}
            >
              議事録
            </div>
          </div>
          <div className={classes.title}>通年資料</div>
          <div className={classes.content}>
            {mainDocuments
              .filter((el) => +el?.year === +meeting?.year)[0]
              ?.data?.map((el) => (
                <div
                  key={el.name}
                  className={classes.link}
                  onClick={() => {
                    if (!edited) {
                      linkFunction(false, { type: "pdf", name: `${meeting?.year}/documents/${el.fileName}` }, "");
                    } else {
                      setNotSavedPopupTrigger({
                        func: linkFunction,
                        params: [false, { type: "pdf", name: `${meeting?.year}/documents/${el.fileName}` }, ""],
                      });
                    }
                  }}
                >
                  {el.name}
                </div>
              ))}
          </div>
        </div>
        <div className={classes.bottom}>
          <div className={classes.bottomItem}>
            <div className={classes.title}>確認事項</div>
            <table>
              <tbody>
                {meeting.discussions
                  ?.filter((discussion) => discussion.phase === "確認")
                  .map((discussion) => {
                    return (
                      <tr key={discussion.number}>
                        <td className={classes.number}>({discussion.number})</td>
                        <td
                          className={classes.link}
                          onClick={() => {
                            if (!edited) {
                              linkFunction(
                                false,
                                {
                                  type: "discussion",
                                  name: discussion.title,
                                  id: discussion.discussion,
                                  phase: "phase3",
                                },
                                ""
                              );
                            } else {
                              setNotSavedPopupTrigger({
                                func: linkFunction,
                                params: [
                                  false,
                                  {
                                    type: "discussion",
                                    name: discussion.title,
                                    id: discussion.discussion,
                                    phase: "phase3",
                                  },
                                  "",
                                ],
                              });
                            }
                          }}
                        >
                          {parse(discussion.title)}
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td className={classes.number}>
                    ({meeting.discussions?.filter((discussion) => discussion.phase === "確認").length + 1})
                  </td>
                  <td>その他</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.bottomItem}>
            <div className={classes.title}>審議事項</div>
            <table>
              <tbody>
                {meeting.discussions
                  ?.filter((discussion) => discussion.phase === "審議")
                  .map((discussion) => {
                    return (
                      <tr key={discussion.number}>
                        <td className={classes.number}>({discussion.number})</td>
                        <td
                          className={classes.link}
                          onClick={() => {
                            if (!edited) {
                              linkFunction(
                                false,
                                {
                                  type: "discussion",
                                  name: discussion.title,
                                  id: discussion.discussion,
                                  phase: "phase2",
                                },
                                ""
                              );
                            } else {
                              setNotSavedPopupTrigger({
                                func: linkFunction,
                                params: [
                                  false,
                                  {
                                    type: "discussion",
                                    name: discussion.title,
                                    id: discussion.discussion,
                                    phase: "phase2",
                                  },
                                  "",
                                ],
                              });
                            }
                          }}
                        >
                          {parse(discussion.title)}
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td className={classes.number}>
                    ({meeting.discussions?.filter((discussion) => discussion.phase === "審議").length + 1})
                  </td>
                  <td>その他</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.bottomItem}>
            <div className={classes.title}>協議事項</div>
            <table>
              <tbody>
                {meeting.discussions
                  ?.filter((discussion) => discussion.phase === "協議")
                  .map((discussion) => {
                    return (
                      <tr key={discussion.number}>
                        <td className={classes.number}>({discussion.number})</td>
                        <td
                          className={classes.link}
                          onClick={() => {
                            if (!edited) {
                              linkFunction(
                                false,
                                {
                                  type: "discussion",
                                  name: discussion.title,
                                  id: discussion.discussion,
                                  phase: "phase1",
                                },
                                ""
                              );
                            } else {
                              setNotSavedPopupTrigger({
                                func: linkFunction,
                                params: [
                                  false,
                                  {
                                    type: "discussion",
                                    name: discussion.title,
                                    id: discussion.discussion,
                                    phase: "phase1",
                                  },
                                  "",
                                ],
                              });
                            }
                          }}
                        >
                          {parse(discussion.title)}
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td className={classes.number}>
                    ({meeting.discussions?.filter((discussion) => discussion.phase === "協議").length + 1})
                  </td>
                  <td>その他</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={classes.bottomItem}>
            <div className={classes.title}>報告事項</div>
            <table>
              <tbody>
                {reports
                  ?.filter((report) => report.meeting === meeting.id)
                  .sort((a, b) => a.number - b.number)
                  .map((report) => {
                    return (
                      <tr key={report.id}>
                        <td className={classes.number}>({report.number+1})</td>
                        <td
                          className={`${report.fileName?classes.link:""}`}
                          onClick={() => {
                            if (report.fileName) {
                              if (!edited) {
                                linkFunction(
                                  false,
                                  {
                                    type: "pdf",
                                    name: `${meeting?.year}/meetings/${report.meeting}/reports/${report.fileName}`,
                                  },
                                  ""
                                );
                              } else {
                                setNotSavedPopupTrigger({
                                  func: linkFunction,
                                  params: [
                                    false,
                                    {
                                      type: "pdf",
                                      name: `${meeting?.year}/meetings/${report.meeting}/${report.fileName}`,
                                    },
                                    "",
                                  ],
                                });
                              }
                            }
                          }}
                        >
                          {parse(report.name)}
                        </td>
                      </tr>
                    );
                  })}
                <tr>
                  <td className={classes.number}>
                    ({reports?.filter((report) => +report.meeting === +meeting.id).length+1})
                  </td>
                  <td>その他</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        className={`${classes.resizer}`}
        onClick={() => {
          setMini(!mini);
        }}
      >
        {mini ? <BiRightArrow /> : <BiLeftArrow />}
      </div>
    </div>
  );
}

export default MeetingSidebar;
