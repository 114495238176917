import React, { useReducer } from "react";
import { Context } from "./context";
import { reducer } from "./reducer";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {
  FETCH_USERS,
  SET_AUTH,
  SET_AUTH_ERROR,
  SET_ADMIN,
  SET_RESPONSE,
  FETCH_MEETINGS,
  FETCH_DISCUSSIONS,
  SET_CURRENT_USER,
  FETCH_PDF,
  FETCH_MAIN_DOCUMENTS,
  FETCH_DISCUSSION,
  FETCH_MEETING,
  FETCH_DISCUSSION_BY_PHASE,
  SET_YEAR,
  FETCH_TEMPLATES,
  FETCH_REPORTS,
} from "./types";

export const State = ({ children }) => {
  const server = process.env.REACT_APP_SERVER_ADDRESS;
  const initialState = {
    auth: false,
    loginError: "",
    currentUser: {},
    admin: false,
    create: false,
    edit: false,
    response: { text: "", link: "" },
    year: new Date().getFullYear(),
    users: [],
    mainDocuments: [],
    meetings: [],
    meeting: {},
    pdf: null,
    meetings1: [],
    discussions: [],
    discussion: {},
    discussionByPhase: [],
    phases: [
      { name: "確認", phase: "phase3" },
      { name: "審議", phase: "phase2" },
      { name: "協議", phase: "phase1" },
      { name: "報告", phase: "phase4" },
    ],
    templates: [],
    reports: [],
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  // Headers
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
      defCharset: "utf8",
      defParamCharset: "utf8",
    },
  };

  //Error handler
  const errorHandler = (error) => {
    setResponse({ text: "エラー発生：" + error, link: "" });
  };

  //Auth
  const LogIn = async (email, password) => {
    axios
      .post(`${server}/api/user/login`, { email, password }, headers)
      .then((res) => {
        dispatch({ type: SET_AUTH, payload: true });
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: SET_CURRENT_USER,
          payload: {
            id: jwtDecode(res.data.token).id,
            name: jwtDecode(res.data.token).name,
            roles: jwtDecode(res.data.token).userRoles?.roles,
            isAdmin: jwtDecode(res.data.token).userRoles?.admin,
          },
        });
        let isAdmin = jwtDecode(res.data.token).userRoles?.admin;
        let hasPremissions = false;
        jwtDecode(res.data.token).userRoles?.roles.forEach((el) => {
          hasPremissions = el.premissions.filter((premission) => premission)[0] || hasPremissions;
        });
        dispatch({
          type: SET_ADMIN,
          payload: isAdmin || hasPremissions || false,
        });
      })
      .catch((e) => {
        let payload = e.response.data.message;
        dispatch({ type: SET_AUTH_ERROR, payload });
      });
  };
  const resetLoginError = () => {
    dispatch({ type: SET_AUTH_ERROR, payload: "" });
  };
  const check = async () => {
    axios
      .get(`${server}/api/user/auth`, headers)
      .then((res) => {
        dispatch({ type: SET_AUTH, payload: true });
        localStorage.setItem("token", res.data.token);
        dispatch({
          type: SET_CURRENT_USER,
          payload: {
            id: jwtDecode(res.data.token).id,
            name: jwtDecode(res.data.token).name,
            roles: jwtDecode(res.data.token).userRoles?.roles,
            isAdmin: jwtDecode(res.data.token).userRoles?.admin,
          },
        });
        let isAdmin = jwtDecode(res.data.token).userRoles?.admin;
        let hasPremissions = false;
        jwtDecode(res.data.token).userRoles?.roles.forEach((el) => {
          hasPremissions = el.premissions.filter((premission) => premission)[0] || hasPremissions;
        });
        dispatch({
          type: SET_ADMIN,
          payload: isAdmin || hasPremissions || false,
        });
      })
      .catch((e) => {
        dispatch({ type: SET_AUTH, payload: false });
        let payload = e.response.data.message;
        dispatch({ type: SET_AUTH_ERROR, payload });
      });
  };
  const logout = () => {
    dispatch({ type: SET_CURRENT_USER, payload: {} });
    dispatch({ type: SET_AUTH, payload: false });
    localStorage.setItem("token", "");
  };

  //sets
  const setResponse = (payload) => {
    dispatch({ type: SET_RESPONSE, payload });
  };

  const setYear = (payload) => {
    dispatch({ type: SET_YEAR, payload });
  };

  //Users
  const fetchUsers = async () => {
    check();
    axios.get(`${server}/api/user/`, headers).then((res) => {
      let payload = res.data.rows.sort((a, b) => a.id - b.id);
      dispatch({ type: FETCH_USERS, payload });
    });
  };
  const createEditUser = async (user) => {
    axios
      .post(`${server}/api/user/${user.id ? "edit" : "create"}`, user, headers)
      .then((res) => {
        let response = { text: "更新完了", link: "/admin/users" };
        setResponse(response);
        fetchUsers();
      })
      .catch((e) => {
        fetchUsers();
        errorHandler(e.response.data.message);
      });
  };
  const disableEnable = async (user) => {
    axios
      .post(`${server}/api/user/disableEnable`, user, headers)
      .then((res) => {
        let response = { text: "更新完了", link: "/admin/users" };
        setResponse(response);
        fetchUsers();
      })
      .catch((e) => {
        fetchUsers();
        errorHandler(e.response.data.message);
      });
  };
  const changePassword = async (type, password, id, year) => {
    axios
      .put(`${server}/api/${type}/changepassword`, { password, id, year }, headers)
      .then((res) => {
        setResponse({ text: "更新完了" });
      })
      .catch((e) => {
        errorHandler(e.response.data.message);
      });
  };

  //MainDocuments
  const fetchMainDocuments = async () => {
    check();
    axios.get(`${server}/api/mainDocument/`, headers).then((res) => {
      let payload = res.data.rows.sort((a, b) => a.year - b.year);
      dispatch({ type: FETCH_MAIN_DOCUMENTS, payload });
    });
  };
  const createMainDocument = (mainDocuments, year, file) => {
    file = file ? { ...file, name: file.file.name } : {};
    let data = new FormData();
    data.append("dir", year);
    data.append("year", year);
    data.append("mainDocuments", JSON.stringify(mainDocuments));
    for (let key in file) {
      data.append(key, file[key]);
    }
    file = data;
    axios
      .put(`${server}/api/mainDocument/edit`, file, headers)
      .then((res) => {
        setResponse({ text: "更新完了" });
        fetchMainDocuments();
      })
      .catch((e) => {
        errorHandler("通年資料の編集権限がありません");
        fetchMainDocuments();
      });
  };
  const createLOM = (year, lom) => {
    let data = { year, lom };
    axios
      .put(`${server}/api/mainDocument/editLom`, data, headers)
      .then((res) => {
        setResponse({ text: "更新完了" });
        fetchMainDocuments();
      })
      .catch((e) => {
        errorHandler("通年資料の編集権限がありません");
      });
  };

  //Meetings
  const fetchMeetings = async () => {
    check();
    axios.get(`${server}/api/meeting/`, headers).then((res) => {
      let payload = res.data.rows?.sort((a, b) => a.id - b.id);
      dispatch({ type: FETCH_MEETINGS, payload });
    });
  };
  const fetchMeeting = async (id) => {
    check();
    axios.get(`${server}/api/meeting/${id}`, headers).then((res) => {
      let payload = res.data;
      dispatch({ type: FETCH_MEETING, payload });
    });
  };
  const createEditMeeting = async (meeting) => {
    let data = new FormData();
    data.append("previousFile", meeting?.previousFile?.file);
    data.append("currentFile", meeting?.currentFile?.file);
    data.append("year", meeting.year);
    data.append("meetingData", JSON.stringify(meeting));
    axios
      .post(`${server}/api/meeting/edit`, data, headers)
      .then((res) => {
        let response = {
          text: `更新完了`,
          link: "/admin/meetings",
        };
        setResponse(response);
        fetchMeetings()
      })
      .catch((e) => {
        errorHandler("会議の作成・編集権限がありません");
      });
  };

  const editMeetingOrder = async (meetings, year) => {
    axios
      .post(`${server}/api/meeting/editMeetingOrder`, { meetings, year }, headers)
      .then((res) => {
        fetchMeetings();
        setResponse({ text: `会議順序を更新しました` });
      })
      .catch((e) => {
        errorHandler(e.response.data.message);
      });
  };

  const deleteMeeting = async (id, year) => {
    axios
      .post(`${server}/api/meeting/delete`, { id, year }, headers)
      .then((res) => {
        setResponse({ text: `削除されました。` });
        fetchMeetings();
      })
      .catch((e) => {
        errorHandler("会議の削除権限がありません");
      });
  };

  //Discussions
  const fetchDiscussions = async () => {
    check();
    axios.get(`${server}/api/discussion/`, headers).then((res) => {
      let payload = res.data.rows?.sort((a, b) => a.id - b.id);
      dispatch({ type: FETCH_DISCUSSIONS, payload });
    });
  };
  const fetchDiscussion = async (id) => {
    check();
    axios.get(`${server}/api/discussion/${id}`, headers).then((res) => {
      let payload = res.data;
      dispatch({ type: FETCH_DISCUSSION, payload });
    });
  };
  const fetchDiscussionByPhase = async (id, phase) => {
    check();
    axios.get(`${server}/api/discussion/${id}`, headers).then((res) => {
      let payload = res.data[phase];
      dispatch({ type: FETCH_DISCUSSION_BY_PHASE, payload });
    });
  };
  const createEditDiscussion = async (discussion, meeting, files, isUser, saveToMeeting, nextStage) => {
    let data = new FormData();
    files?.forEach((file) => {
      data.append("files", file.file);
    });
    data.append("year", discussion.year);
    data.append("discussionData", JSON.stringify(discussion));
    data.append("nextStage", nextStage);
    axios
      .post(`${server}/api/discussion/${discussion.id ? "edit" : "create"}`, data, headers)
      .then((res) => {
        if (res.data.error) {
          setResponse({ text: res.data.error });
        } else {
          let response = {
            text: `更新完了`,
            link: isUser ? "" : "/admin/meetings",
          };
          setResponse(response);
          fetchDiscussions();
          if (!discussion.id || res.data.saveToMeeting || saveToMeeting) {
            axios
              .post(
                `${server}/api/meeting/addDiscussion`,
                {
                  id: meeting,
                  discussion: {
                    title: discussion.title,
                    discussion: discussion.id || res.data.id,
                    phase: discussion.phase,
                  },
                  year: discussion.year,
                },
                headers
              )
              .then((res) => {});
          } else {
            fetchDiscussionByPhase(discussion.id, state.phases.filter((el) => el.name === discussion.phase)[0]?.phase);
          }
        }
      })
      .catch((e) => {
        fetchMeetings();
        errorHandler(e.response.data.message);
      });
  };
  const copyFiles = async (previus, current) => {
    axios
      .post(`${server}/api/discussion/copyFiles`, { previus, current }, headers)
      .then((res) => {
        console.log("OK");
      })
      .catch((e) => {
        errorHandler(e.response.data.message);
      });
  };
  const deleteDiscussion = async (id, discussionId, phase, year) => {
    axios
      .post(`${server}/api/meeting/deleteDiscussion`, { id, discussionId, phase, year }, headers)
      .then((res) => {
        setResponse({ text: `議案が削除されました` });
        fetchMeetings();
      })
      .catch((e) => {
        errorHandler("議案の削除権限がありません");
      });
  };
  const editDiscussionOrder = async (id, discussions, year) => {
    /*console.log(id);
    console.log(discussions);
    console.log(year);*/
    axios
      .post(`${server}/api/meeting/editDiscussionOrder`, { id, discussions, year }, headers)
      .then((res) => {
        setResponse({ text: `議案順序を更新しました` });
        fetchMeetings();
      })
      .catch((e) => {
        errorHandler("権限がないため議案順序は更新できません");
      });
  };

  const createDiscussion = async (meeting) => {
    axios
      .put(`${server}/api/meeting/edit`, meeting, headers)
      .then((res) => {
        setResponse({ text: `更新完了` });
        fetchMeetings();
      })
      .catch((e) => {
        errorHandler(e.response.data.message);
      });
  };

  //Reports
  const fetchReports = async () => {
    check();
    axios.get(`${server}/api/report/`, headers).then((res) => {
      let payload = res.data.rows.sort((a, b) => a.year - b.year);
      dispatch({ type: FETCH_REPORTS, payload });
    });
  };

  const createEditReport = (report, file, year) => {
    file = file ? { ...file, name: file.file.name } : {};
    let data = new FormData();
    data.append("report", JSON.stringify(report));
    data.append("year", year);
    for (let key in file) {
      data.append(key, file[key]);
    }
    file = data;
    console.log(data)
    axios
      .put(`${server}/api/report/createEdit`, file, headers)
      .then((res) => {
        setResponse({ text: "更新完了" });
        fetchReports();
      })
      .catch((e) => {
        errorHandler("議案の作成・編集権限がありません");
      });
  };

  const editReportOrder = async (reports, year) => {
    axios
      .post(`${server}/api/report/editReportOrder`, { reports, year }, headers)
      .then((res) => {
        fetchReports();
      })
      .catch((e) => {
        errorHandler(e.response.data.message);
      });
  };

  const deleteReport = async (id,year) => {
    axios
      .post(`${server}/api/report/delete`, { id, year}, headers)
      .then((res) => {
        setResponse({ text: `削除されました` });
        fetchReports();
      })
      .catch((e) => {
        errorHandler("議案の削除権限がありません");
      });
  };

  //Files
  const fetchFile = async (fileName) => {
    check();
    axios.get(`${server}/files/${fileName}`, { ...headers, responseType: "arraybuffer" }).then((res) => {
      let payload = URL.createObjectURL(new Blob([res.data], { type: "application/pdf" }));
      dispatch({ type: FETCH_PDF, payload });
    });
  };
  //Templates
  const fetchTemplates = async (id) => {
    check();
    axios.get(`${server}/api/template/`, headers).then((res) => {
      let payload = res.data.rows.sort((a, b) => a.id - b.id);
      console.log(payload);
      dispatch({ type: FETCH_TEMPLATES, payload });
    });
  };
  const createEditTemplate = async (name, data, year) => {
    axios
      .post(`${server}/api/template/create`, { name, data, year }, headers)
      .then((res) => {
        let response = { text: "更新完了" };
        setResponse(response);
        fetchTemplates();
      })
      .catch((e) => {
        fetchTemplates();
        errorHandler(e.response.data.message);
      });
  };
  const deleteTemplate = async (id, year) => {
    axios
      .post(`${server}/api/template/delete`, { id, year }, headers)
      .then((res) => {
        setResponse({ text: `テンプレートが削除されました` });
        fetchTemplates();
      })
      .catch((e) => {
        errorHandler(e.response.data.message);
      });
  };
  return (
    <Context.Provider
      value={{
        LogIn,
        resetLoginError,
        check,
        logout,
        setResponse,
        setYear,
        changePassword,
        fetchUsers,
        createEditUser,
        fetchMainDocuments,
        createMainDocument,
        createLOM,
        fetchMeetings,
        fetchMeeting,
        createEditMeeting,
        deleteMeeting,
        createDiscussion,
        fetchDiscussions,
        fetchDiscussion,
        fetchDiscussionByPhase,
        createEditDiscussion,
        deleteDiscussion,
        editDiscussionOrder,
        fetchFile,
        disableEnable,
        fetchTemplates,
        createEditTemplate,
        deleteTemplate,
        fetchReports,
        createEditReport,
        editReportOrder,
        deleteReport,
        editMeetingOrder,
        auth: state.auth,
        loginError: state.loginError,
        users: state.users,
        create: state.create,
        edit: state.edit,
        response: state.response,
        phases: state.phases,
        mainDocuments: state.mainDocuments,
        year: state.year,
        meetings: state.meetings,
        meeting: state.meeting,
        discussions: state.discussions,
        discussion: state.discussion,
        discussionByPhase: state.discussionByPhase,
        pdf: state.pdf,
        currentUser: state.currentUser,
        admin: state.admin,
        templates: state.templates,
        reports: state.reports,
        state: state,
        server,
      }}
    >
      {children}
    </Context.Provider>
  );
};
