import { useContext, useEffect, useState } from "react";
import { Context } from "../../../Store/context";
import classes from "./MainDocuments.module.scss";
import { AiOutlineFileAdd } from "react-icons/ai";
import { AiFillEdit } from "react-icons/ai";
import Editor from "../../Meeting/Discussion/DiscussionComponents/Editor/Editor";
import parse from "html-react-parser";
import PDFViewer from "./PDFViewer/PDFViewer";

function MainDocuments() {
  const { currentUser, mainDocuments, fetchMainDocuments, createMainDocument, createLOM, year } = useContext(Context);
  const [currentYear, setCurrentYear] = useState(+year);
  const [mainDocumentsForm, setMainDocumentsForm] = useState(
    mainDocuments.filter((el) => +el.year === +currentYear)[0]?.data || []
  );
  const [addDocument, setAddDocument] = useState(false);
  const [addLOM, setAddLOM] = useState(false);
  const [newDocument, setNewDocument] = useState({});
  const [LOM, setLOM] = useState("");
  const [file, setFile] = useState(null);
  useEffect(() => {
    fetchMainDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setMainDocumentsForm(mainDocuments?.filter((el) => +el.year === +currentYear)[0]?.data);
    setLOM(mainDocuments?.filter((el) => +el.year === +currentYear)[0]?.lom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainDocuments, currentYear]);
  let years = [];
  if (currentUser.isAdmin) {
    for (let i = 2022; i < +new Date().getFullYear() + 10; i++) {
      years.push(i);
    }
  } else {
    years = currentUser.roles.filter((el) => el.premissions[1] || el.premissions[0]).map((el) => el.year);
  }
  const onSubmit = (e) => {
    e.preventDefault();
    setAddDocument(false);
    //setMainDocumentsForm(mainDocuments||[]);
    setMainDocumentsForm(mainDocumentsForm ? [...mainDocumentsForm, newDocument] : [newDocument]);
    setNewDocument({});
    createMainDocument(mainDocumentsForm ? [...mainDocumentsForm, newDocument] : [newDocument], currentYear, file);
  };
  const onAddLOM = () => {
    setAddLOM(false);
    //setMainDocumentsForm(mainDocuments||[]);
    createLOM(currentYear, LOM);
  };
  const [PDF, setPDF] = useState(false);
  const [url, setUrl] = useState(false);
  const [deleteDocument, setDeleteDocument] = useState("");
  return (
    <div className="content">
      <div className={`${classes.tabs}`}>
        {years.map((year) => (
          <div
            key={year}
            className={`${classes.tab} ${+currentYear === +year ? classes.current : ""} btn`}
            onClick={() => {
              setCurrentYear(year);
            }}
          >
            {year}
          </div>
        ))}
      </div>
      <div className={classes.plates}>
        {PDF ? (
          <PDFViewer url={url} setPDF={setPDF} />
        ) : (
          <div className={classes.plate}>
            <div className={classes.lomWrapper}>
              <div className={classes.title}>
                タイトル　
                <div className={`${classes.btn}  ${classes.editLom} btn`} onClick={() => setAddLOM(true)}>
                  <AiFillEdit />
                  タイトル編集
                </div>
              </div>
              {addLOM ? (
                <div className={classes.editor}>
                  <Editor fieldValue={LOM || ""} setFc={setLOM} />
                  <div className={classes.editorBtns}>
                    <div className={`${classes.btn} btn greenBtn`} onClick={onAddLOM}>
                      更新
                    </div>
                    <div
                      className={`${classes.btn} btn`}
                      onClick={() => {
                        setAddLOM(false);
                        fetchMainDocuments();
                      }}
                    >
                      キャンセル
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className={classes.lom}>{parse(typeof LOM === "string" ? LOM : "")}</div>
                </>
              )}
            </div>
            <hr />
            <div className={classes.title}>通年資料</div>
            {mainDocumentsForm
              ?.filter((document) => +document.currentYear === +currentYear)
              .map((document, i) => (
                <>
                  <div className={classes.formGroup} key={document.name}>
                    <div className={classes.name}>{document.name}</div>
                    <div
                      className={classes.pdfFileName}
                      onClick={() => {
                        setPDF(true);
                        setUrl(`${year}/documents/${document.fileName}`);
                      }}
                    >
                      {document.fileName}
                    </div>
                    <div
                      className={`${classes.btn} btn redBtn`}
                      onClick={() => {
                       setDeleteDocument(i)
                      }}
                    >
                      削除
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            {addDocument ? (
              <form onSubmit={onSubmit} className={classes.formGroup}>
                <label>表示名</label>
                <input
                  required
                  className="input"
                  type="text"
                  value={newDocument?.name || ""}
                  onChange={(e) => {
                    setNewDocument({ ...newDocument, name: e.target.value, currentYear });
                  }}
                />
                <input
                  required
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => {
                    setNewDocument({ ...newDocument, fileName: e.target.files[0]?.name, currentYear });
                    setFile({ file: e.target.files[0], fileSrc: URL.createObjectURL(e.target.files[0]) });
                  }}
                />
                <button className={`${classes.btn} btn greenBtn`}>更新</button>
                <div
                  className={`btn`}
                  onClick={() => {
                    setAddDocument(false);
                  }}
                >
                  キャンセル
                </div>
              </form>
            ) : null}
            <div className={`${classes.btn} btn`} onClick={() => setAddDocument(true)}>
              <AiOutlineFileAdd />
              ファイル追加
            </div>
          </div>
        )}
      </div>
      {deleteDocument ||  deleteDocument===0? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  mainDocumentsForm.splice(deleteDocument, 1);
                       setMainDocumentsForm([...mainDocumentsForm]);
                       createMainDocument([...mainDocumentsForm], currentYear);
                       //deleteFile(document.fileName, currentYear.toString(), currentYear.toString());
                  setDeleteDocument(false);
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setDeleteDocument(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default MainDocuments;
