import { useContext, useEffect } from "react";
import { Context } from "../../../../../Store/context";
import classes from "./PDFViewer.module.scss";

function PDFViewer({ url, setPDF }) {
  const { fetchFile, pdf } = useContext(Context);
  useEffect(() => {
    fetchFile(`${url}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
  return (
    <div className={classes.pdf}>
      <iframe src={pdf} frameBorder="0" title={url}></iframe>
      <div className={`${classes.back} btn`} onClick={()=>{setPDF(false)}}>戻る</div>
    </div>
  );
}

export default PDFViewer;
