import { useContext, useEffect } from "react";
import { Context } from "../../../Store/context";
import classes from "./HomePageHeader.module.scss";
import parse from "html-react-parser";

function HomePageHeader({ setCreate, year }) {
  const { mainDocuments, fetchMainDocuments, role } = useContext(Context);
  useEffect(() => {
    fetchMainDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={classes.header}>
      <img src="/images/JCIlogo.jpg" alt="logo" width={246} />
      <div className={classes.year}>
        <span>{year}</span>年度
      </div>
      <div className={classes.title}>一般社団法人富岡青年会議所</div>
      <div className={classes.lom}>
        {/* {year}年度LOMスローガン */}
        <br />
        {parse(mainDocuments.filter((el) => +el?.year === +year)[0]?.lom || "")}
      </div>
      {role < 3 ? (
        <div
          className={`${classes.addMeetingBtn} btn`}
          onClick={() => {
            setCreate(true);
          }}
        >
          会議を追加
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default HomePageHeader;
