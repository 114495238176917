import { useContext, useEffect, useState } from "react";
import ChooseYear from "../components/Homepage/ChooseYear/ChooseYear";
import HomePageContent from "../components/Homepage/HomePageContent/HomePageContent";
import HomePageHeader from "../components/Homepage/HomePageHeader/HomePageHeader";
//import CreateEditMeeting from "../components/CreateEdit/CreateEditMeeting/CreateEditMeeting";

import { Context } from "../Store/context";

function HomePage() {
  const { fetchMeetings, meetings, year, setCreate, setYear } = useContext(Context);
  useEffect(() => {
    fetchMeetings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [chooseYear, setChooseYear] = useState(false);
  return (
    <div className="HomePage">
      {chooseYear ? (
        <>
          <div className="btn" onClick={() => setChooseYear(false)}>
            キャンセル
          </div>
          <ChooseYear setChooseYear={setChooseYear} meetings={meetings} setYear={setYear} />
        </>
      ) : (
        <>
          <div className="btn yearBtn" onClick={() => setChooseYear(true)}>
            年度選択
          </div>
          <HomePageHeader setCreate={setCreate} year={year} />
          <HomePageContent year={year} meetings={meetings} />
        </>
      )}
    </div>
  );
}

export default HomePage;
