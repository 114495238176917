import classes from "./CreateEditDiscussion.module.scss";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Context } from "../../../../Store/context";
import { AiTwotoneDelete, AiOutlineUserAdd } from "react-icons/ai";
import Section from "./Section/Section";

export default function CreateEditDiscussion() {
  const {
    createEditDiscussion,
    users,
    fetchUsers,
    setResponse,
    fetchDiscussion,
    discussion,
    discussions,
    fetchDiscussions,
    phases,
    year,
    fetchTemplates,
    templates,
    createEditTemplate,
    deleteTemplate,
    fetchMeetings,
    meetings,
  } = useContext(Context);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [noEdit, setNoEdit] = useState(false)
  useEffect(() => {
    fetchUsers();
    fetchTemplates();
    fetchMeetings();
    if (+id) {
      fetchDiscussion(id);
    } else {
      fetchDiscussions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let phase = phases.filter((el) => el.name === searchParams.get("phase"))[0]?.phase;
  let previousPhase = "phase" + (phase[phase?.length - 1] - 1);
  useEffect(() => {
    if (+id && discussion) {
      let lastHistory = discussion[phase]?.length - 1 || 0;
      let currentDiscussion = discussion[phase] ? discussion[phase][lastHistory] : [];
      setDiscussionForm({
        phase: searchParams.get("phase"),
        year: year,
        id: discussion?.id,
        title: currentDiscussion?.title || "",
        name: currentDiscussion?.name || "",
        about: currentDiscussion?.about || "",
        department: currentDiscussion?.department || "",
        author: currentDiscussion?.author || 0,
        checkers: currentDiscussion?.checkers || [],
        added: currentDiscussion?.added || new Date().toLocaleDateString(),
        edited: new Date().toLocaleDateString(),
        isChecked: currentDiscussion?.isChecked || false,
        sections: currentDiscussion?.sections || [],
        history: currentDiscussion?.history || 0,
        isMovable: currentDiscussion?.isMovable || false,
        isRemand: currentDiscussion?.isRemand || false,
      });
      setNoEdit(currentDiscussion?.isChecked)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussion]);
  const [addChecker, setAddChecker] = useState(false);
  const [newChecker, setNewChecker] = useState({ checker: 0, isChecked: false, date: "" });
  const [addSection, setAddSection] = useState(false);
  const [newSection, setNewSection] = useState(false);
  const [deleteChecker, setDeleteChecker] = useState(false);
  const [discussionSelect, setDiscussionSelect] = useState(+id);
  const [discussionSelect2, setDiscussionSelect2] = useState(+id);
  const [saveToMeeting, setSaveToMeeting] = useState(false);
  const [movable, setMovable] = useState(false);
  const [remand, setRemand] = useState(false);
  const [nextStage, setNextStage] = useState(false);
  const [createTemplate, setCreateTemplate] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [showTemplatesList, setShowTemplatesList] = useState("");
  const [showDeleteTemplate, setShowDeleteTemplate] = useState("");
  const [discussionForm, setDiscussionForm] = useState({
    year: year || "",
    title: "",
    name: "",
    about: "",
    department: "",
    author: 0,
    checkers: [],
    phase: searchParams.get("phase"),
    added: new Date().toLocaleString(),
    edited: new Date().toLocaleString(),
    isChecked: false,
    sections: [],
    history: 0,
    isMovable: false,
    isRemand: false,
  });
  const onDiscussionSelect = (e) => {
    setNextStage(true);
    setDiscussionSelect(e.target.value);
    setDiscussionSelect2(0);
    if (+e.target.value) {
      let lastHistory = discussions.filter((el) => +el.id === +e.target.value)[0][previousPhase]?.length - 1 || 0;
      let newPhaseDiscussion = discussions.filter((el) => +el.id === +e.target.value)[0][previousPhase][lastHistory];
      let lastHistory2 = "";
      let Phase2Discussion = "";
      let remandHistory = false;
      let nan = ""
      try {
        lastHistory2 = discussions.filter((el) => +el.id === +e.target.value)[0]["phase2"]?.length - 1 || 0;
        Phase2Discussion = discussions.filter((el) => +el.id === +e.target.value)[0]["phase2"][lastHistory2];
        discussions.filter((el) => +el.id === +e.target.value)[0]["phase2"].map((e) => {
          e.isRemand ? remandHistory = true :  nan = "" ;
        })
      } catch(e) {}
      if (previousPhase === "phase2") {
        remandHistory = false;
      }
      setDiscussionForm({
        phase: searchParams.get("phase"),
        year: year,
        id: +e.target.value,
        title: newPhaseDiscussion?.title || "",
        name: newPhaseDiscussion?.name || "",
        about: newPhaseDiscussion?.about || "",
        department: newPhaseDiscussion?.department || "",
        author: newPhaseDiscussion?.author || 0,
        checkers: [],
        added: newPhaseDiscussion?.added || new Date().toLocaleDateString(),
        edited: new Date().toLocaleDateString(),
        isChecked: false,
        sections: newPhaseDiscussion?.sections || [],
        history: remandHistory ? Phase2Discussion.history+1 : 0,
        isMovable: false,
        isRemand: false,
      });
      if (remandHistory && previousPhase !== "phase2") {
        setSaveToMeeting(true);
      }
    }
  };
  const onMovableDiscussionSelect = (e) => {
    setNextStage(true);
    setDiscussionSelect2(e.target.value);
    setDiscussionSelect(0);
    if (+e.target.value) {
      let lastHistory = discussions.filter((el) => +el.id === +e.target.value)[0][phase].length - 1 || 0;
      let movableDiscussion = discussions.filter((el) => +el.id === +e.target.value)[0][phase][lastHistory];
      setDiscussionForm({
        phase: searchParams.get("phase"),
        year: year,
        id: +e.target.value,
        title: movableDiscussion?.title || "",
        name: movableDiscussion?.name || "",
        about: movableDiscussion?.about || "",
        department: movableDiscussion?.department || "",
        author: movableDiscussion?.author || 0,
        checkers: [],
        added: movableDiscussion?.added || new Date().toLocaleDateString(),
        edited: new Date().toLocaleDateString(),
        isChecked: false,
        sections: movableDiscussion?.sections || [],
        history: movableDiscussion.history + 1,
        isMovable: false,
      });
      setSaveToMeeting(true);
    } else {
      setDiscussionForm({
        id: undefined,
        year: year || "",
        title: "",
        name: "",
        about: "",
        department: "",
        author: 0,
        checkers: [],
        phase: searchParams.get("phase"),
        added: new Date().toLocaleString(),
        edited: new Date().toLocaleString(),
        isChecked: false,
        sections: [],
        history: 0,
        isMovable: false,
      });
    }
  };
  let phaseRemand = "phase2";
  const onRemandDiscussionSelect = (e) => {
    setNextStage(true);
    setDiscussionSelect2(e.target.value);
    setDiscussionSelect(0);
    if (+e.target.value) {
      let lastHistory = discussions.filter((el) => +el.id === +e.target.value)[0][phaseRemand].length - 1 || 0;
      let remandDiscussion = discussions.filter((el) => +el.id === +e.target.value)[0][phaseRemand][lastHistory];
      setDiscussionForm({
        phase: searchParams.get("phase"),
        year: year,
        id: +e.target.value,
        title: remandDiscussion?.title || "",
        name: remandDiscussion?.name || "",
        about: remandDiscussion?.about || "",
        department: remandDiscussion?.department || "",
        author: remandDiscussion?.author || 0,
        checkers: [],
        added: remandDiscussion?.added || new Date().toLocaleDateString(),
        edited: new Date().toLocaleDateString(),
        isChecked: false,
        sections: remandDiscussion?.sections || [],
        history: remandDiscussion.history + 1,
        isRemand: false,
      });
      setSaveToMeeting(true);
    } else {
      setDiscussionForm({
        id: undefined,
        year: year || "",
        title: "",
        name: "",
        about: "",
        department: "",
        author: 0,
        checkers: [],
        phase: searchParams.get("phase"),
        added: new Date().toLocaleString(),
        edited: new Date().toLocaleString(),
        isChecked: false,
        sections: [],
        history: 0,
        isRemand: false,
      });
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    createEditDiscussion(
      discussionForm,
      searchParams.get("meeting"),
      null,
      null,
      saveToMeeting,
      discussionForm.phase !== "協議" ? nextStage : false
    );
  };
  const saveElement = (element, s, e) => {
    let sections = discussionForm.sections;
    /*console.log(sections);
    console.log(s);
    console.log(e);*/
    sections[s].elements[e] = element;
    setDiscussionForm({ ...discussionForm, sections: [...sections] });
  };
  const saveElementField = (field, s, e, f) => {
    let sections = discussionForm.sections;
    /*console.log(sections);
    console.log(s);
    console.log(e);
    console.log(f);*/
    sections[s].elements[e].fields[f] = field;
    setDiscussionForm({ ...discussionForm, sections: [...sections] });
  };
  // console.log("noEdit ="+noEdit)
  return (
    <div className={classes.wrapper}>
      <div className={classes.meetingInfo}>
        <div>会議名　：{meetings.filter((el) => +el.id === +searchParams.get("meeting"))[0]?.name}</div>
        <div>日時　　：{meetings.filter((el) => +el.id === +searchParams.get("meeting"))[0]?.date}</div>
      </div>
      {searchParams.get("phase") === "協議" || +id ? (
        ""
      ) : (
        <div className={classes.formGroup}>
          <label>議案</label>
          <select onChange={onDiscussionSelect} value={discussionSelect || 0}>
            <option value="0">選択</option>
            {
              phase !== "phase3" ? ( 
                discussions
                ?.filter((el) => (!el[phase] && el[previousPhase]) || el[phase][el[phase].length-1].isRemand)
                ?.map((el) => el[previousPhase][el[previousPhase]?.length - 1])
                ?.filter((el) => el.isChecked)
                ?.map((discussion) => {
                  return <option value={discussion.id}>{discussion.title}</option>;
                })
              ) : (
                discussions
                ?.filter((el) => (!el[phase] && el[previousPhase]))
                ?.map((el) => el[previousPhase][el[previousPhase]?.length - 1])
                ?.filter((el) => el.isChecked)
                ?.map((discussion) => {
                  return <option value={discussion.id}>{discussion.title}</option>;
                })
              )
            }
          </select>
        </div>
      )}
      {!+id ? (
        <>
          <div className={classes.formGroup}>
            <label>持ち越された議案から選択</label>
            <select onChange={onMovableDiscussionSelect} value={discussionSelect2 || 0}>
              <option value="0">選択</option>
              {discussions
                ?.map((el) => {
                  if (el[phase]) {
                    return el[phase][el[phase]?.length - 1];
                  }
                  return null;
                })
                ?.filter((el) => el?.isMovable)
                ?.map((discussion) => {
                  return (
                    <option key={discussion.id} value={discussion.id}>
                      {discussion.title}
                    </option>
                  );
                })}
            </select>
          </div>
          {searchParams.get("phase") === "協議" ? (
          <div className={classes.formGroup}>
            <label>差し戻された議案から選択</label>
            <select onChange={onRemandDiscussionSelect} value={discussionSelect2 || 0}>
              <option value="0">選択</option>
              {discussions.map((el) => {
                if (el.phase2 !== null) {
                  if (el.phase2[el.phase2.length-1].isRemand === true) {
                    return (
                      <option key={el.id} value={el.id}>
                        {el.phase2[0].title}
                      </option>
                    );
                  }
                }
                return "";
              })}
              {/* {discussions
                ?.map((el) => {
                  if (el[phase]) {
                    return el[phase][el[phase]?.length - 1];
                  }
                  return null;
                })
                ?.filter((el) => el?.isRemand)
                ?.map((discussion) => {
                  return (
                    <option key={discussion.id} value={discussion.id}>
                      {discussion.title}
                    </option>
                  );
                })} */}
            </select>
          </div>
          ) : (
            <></>
          )}
          {searchParams.get("phase") !== "協議" && !(+discussionSelect || +discussionSelect2) ? (
            <div className={`${classes.formGroup}`}>
              <Link to="/admin/meetings">
                <div className={`${classes.btns} btn`}>戻る</div>
              </Link>
            </div>
          ) : null}
        </>
      ) : null}
      {(searchParams.get("phase") === "協議" || +discussionSelect || +discussionSelect2) &&
      searchParams.get("phase") !== "報告" ? (
        <form onSubmit={onSubmit}>
          <div className={classes.formGroups}>
            <div className={classes.mainInfo}>
              <div className={classes.title}>議案　基本情報</div>
              <div className={classes.formGroup}>
                <label>議案名</label>
                {+id ? (
                  discussionForm.title
                ) : (
                  <input
                    required
                    className="input"
                    type="text"
                    value={discussionForm.title}
                    onChange={(e) => {
                      setDiscussionForm({ ...discussionForm, title: e.target.value });
                    }}
                  />
                )}
              </div>
              <div className={classes.formGroup}>
                <label>事業名</label>
                <input
                  required
                  className="input"
                  type="text"
                  value={discussionForm.name}
                  onChange={(e) => {
                    setDiscussionForm({ ...discussionForm, name: e.target.value });
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label>業務分掌</label>
                <input
                  required
                  className="input"
                  type="text"
                  value={discussionForm.about}
                  onChange={(e) => {
                    setDiscussionForm({ ...discussionForm, about: e.target.value });
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label>委員会名</label>
                <input
                  required
                  className="input"
                  type="text"
                  value={discussionForm.department}
                  onChange={(e) => {
                    setDiscussionForm({ ...discussionForm, department: e.target.value });
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label>文書作成者名・役職</label>
                <select
                  value={discussionForm?.author}
                  onChange={(e) => setDiscussionForm({ ...discussionForm, author: e.target.value })}
                >
                  <option value="0">選択</option>
                  {users
                    .filter((user) => !user?.userRoles?.admin)
                    .filter((user) => user.active)
                    .map((user) => (
                      <option value={user.id} key={user.id}>
                        {user.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className={`${classes.formGroup}`}>
                <label>確認者</label>
                <div className={classes.checkersWrapper}>
                  <div className={classes.checkers}>
                    {discussionForm?.checkers?.map((checker) => (
                      <div key={checker.checker} className={classes.checker}>
                        <div className={classes.name}>{users.filter((e) => +e.id === +checker.checker)[0]?.name}</div>
                        <div>{new Date(checker?.date).toLocaleDateString()}</div>
                        <span className={`${classes.delete}`} onClick={() => setDeleteChecker(checker)}>
                          <AiTwotoneDelete />
                        </span>
                      </div>
                    ))}
                  </div>
                  <div
                    className={`${classes.addChecker}`}
                    onClick={() => {
                      setAddChecker(true);
                      setNewChecker({ checker: 0, isChecked: false, date: "" });
                    }}
                  >
                    <AiOutlineUserAdd />
                  </div>
                </div>
              </div>
              {id ? (
                <div className={`${classes.formGroup}`}>
                  {discussionForm.isMovable ? (
                    <div className={`${classes.message}`}>次回に持ち越されました</div>
                  ) : (
                    <>              
                      {discussionForm.isRemand ? (
                        <></>
                      ) : (
                        <div
                          onClick={() => {
                            setMovable(true);
                          }}
                          className={`${classes.anotherBtn} btn redBtn`}
                        >
                          次回に持ち越し
                        </div>
                      )}
                    </>
                  )}
                  {searchParams.get("phase") === "審議" ? (
                    <>
                      {discussionForm.isRemand ? (
                        <div className={`${classes.message}`}>協議に差し戻されました</div>
                      ) : (
                        <>
                        {discussionForm.isMovable ? (
                          <></>
                        ) : (
                          <div className={`${classes.anotherBtn} btn redBtn`} onClick={() => {setRemand(true)}}>協議に差し戻す</div>
                        )}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                ""
              )}
              <div className={`${classes.formGroup}`}>
                <button className={`${classes.btns} btn greenBtn`}>更新</button>
                <Link to="/admin/meetings">
                  <div className={`${classes.btns} btn`}>戻る</div>
                </Link>
              </div>
            </div>
            <div className={`${classes.content} ${classes.sections}`}>
              <div className={classes.title}>議案　詳細情報</div>
              <div>
                <div className={`${classes.formGroup__template} ${classes.formGroup}`}>
                  <div className={`${classes.formGroup} ${classes.formGroup__templateSelect}`}>
                    {noEdit?"":<select
                      name=""
                      id=""
                      value={selectedTemplate}
                      onChange={(e) => {
                        setSelectedTemplate(e.target.value);
                        console.log(discussionForm.sections);
                        console.log(templates?.filter((template) => +template.id === +selectedTemplate)[0]?.data);
                      }}
                    >
                      {selectedTemplate ? "" : <option value="">テンプレートから作成</option>}
                      {templates?.map((template) => (
                        <option value={template.id}>{template.name}</option>
                      ))}
                    </select>}
                    {selectedTemplate ? (
                      <div
                        className="btn greenBtn"
                        onClick={() => {
                          setDiscussionForm({
                            ...discussionForm,
                            sections: [...templates?.filter((template) => +template.id === +selectedTemplate)[0]?.data],
                          });
                          setResponse({
                            text: `テンプレート [ ${
                              templates.filter((el) => el.id === +selectedTemplate)[0]?.name
                            } ] から作成しました`,
                          });
                          setSelectedTemplate("");
                        }}
                      >
                        実行
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className={`${classes.formGroup} ${classes.formGroup__templateSelect}`}>
                    <div
                      className={`btn ${classes.mr20}`}
                      onClick={() => {
                        setShowTemplatesList(true);
                      }}
                    >
                      テンプレート一覧
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        setCreateTemplate(true);
                      }}
                    >
                      テンプレートとして保存
                    </div>
                  </div>
                </div>
              </div>
              {discussionForm?.sections?.map((section, i) => (
                <div className={classes.sectionWrapper}>
                  <Section
                    section={section}
                    saveElement={saveElement}
                    saveElementField={saveElementField}
                    discussionForm={discussionForm}
                    key={section.name}
                    sectionNumber={i}
                    setDiscussionForm={setDiscussionForm}
                    setResponse={setResponse}
                    noEdit={noEdit}
                  />
                </div>
              ))}
              {noEdit?"":<div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setAddSection(true);
                  setNewSection({});
                }}
              >
                大分類　追加
              </div>}
            </div>
          </div>
        </form>
      ) : searchParams.get("phase") === "報告" && (+discussionSelect || +discussionSelect2) ? (
        <form onSubmit={onSubmit}>
          {id ? (
            <div className={`${classes.formGroup}`}>
              {discussionForm.isMovable ? (
                <div className={`${classes.message}`}>次回に持ち越されました</div>
              ) : (
                <div
                  onClick={() => {
                    setMovable(true);
                  }}
                  className={`${classes.anotherBtn} btn redBtn`}
                >
                  次回に持ち越し
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          <div className={`${classes.formGroup}`}>
            <button className={`${classes.btns} btn greenBtn`}>更新</button>
            <Link to="/admin/meetings">
              <div className={`${classes.btns} btn`}>戻る</div>
            </Link>
          </div>
        </form>
      ) : (
        ""
      )}
      {addChecker ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>確認者</div>
            <div className={`popupFormGroup`}>
              <label htmlFor="">氏名</label>
              <select
                className={classes.checkersSelect_select}
                value={newChecker.checker}
                onChange={(e) => setNewChecker({ ...newChecker, checker: e.target.value })}
              >
                <option value="0">選択</option>
                {users
                  .filter((user) => !user?.userRoles?.admin)
                  .filter((user) => user.active)
                  .map((user) => (
                    <option value={user.id} key={user.id}>
                      {user.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className={classes.checkersSelect}>
              <div className={`popupFormGroup`}>
                <label htmlFor=""> 確認期限日</label>
                <input
                  className="input"
                  type="date"
                  value={newChecker.date}
                  onChange={(e) => setNewChecker({ ...newChecker, date: e.target.value })}
                />
              </div>
            </div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  if (discussionForm.checkers.filter((el) => +el.checker === +newChecker.checker)[0]) {
                    setResponse({ text: "確認者が既に登録されています" });
                  } else if (!newChecker.date) {
                    setResponse({ text: "確認期限日を入力してください" });
                  } else if (+newChecker.checker) {
                    setAddChecker(false);
                    setDiscussionForm({
                      ...discussionForm,
                      checkers: [...discussionForm.checkers, newChecker],
                    });
                  } else {
                    setResponse({ text: "確認者を選択してください" });
                  }
                }}
              >
                一時保存
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setAddChecker(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {addSection ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}></div>
            <div className={`popupFormGroup`}>
              <label>項目名</label>
              <input
                required
                className="input"
                type="text"
                value={newSection?.name}
                onChange={(e) => {
                  setNewSection({ name: e.target.value, elements: [] });
                }}
              />
            </div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  setAddSection(false);
                  setDiscussionForm({ ...discussionForm, sections: [...discussionForm.sections, newSection] });
                }}
              >
                追加
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setAddSection(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {deleteChecker || deleteChecker === 0 ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>確認者を削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  let index = discussionForm.checkers.indexOf(deleteChecker);
                  let temp = [...discussionForm.checkers];
                  temp.splice(index, 1);
                  setDiscussionForm({ ...discussionForm, checkers: temp });
                  setResponse({ text: "削除されました" });
                  setDeleteChecker(false);
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setDeleteChecker(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {movable ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>次回に持ち越します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  setDiscussionForm({ ...discussionForm, isMovable: true });
                  setMovable(false);
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setMovable(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {remand ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>協議に差し戻します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  setDiscussionForm({ ...discussionForm, isRemand: true });
                  setRemand(false);
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {setRemand(false)}}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {createTemplate ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}></div>
            <div className={`popupTitle danger`}>同一名称があった場合、上書きされます</div>
            <div className={`popupFormGroup`}>
              <label htmlFor="">テンプレート名</label>
              <input
                type="text"
                value={templateName}
                onChange={(e) => {
                  setTemplateName(e.target.value);
                }}
              />
            </div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  createEditTemplate(templateName, discussionForm.sections, year);
                  setCreateTemplate(false);
                  setTemplateName("");
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setCreateTemplate(false);
                  setTemplateName("");
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showTemplatesList ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>テンプレート一覧</div>
            {templates.map((template) => {
              return (
                <div className={`popupFormGroup popupFormGroupBtn`}>
                  <div>{template.name}</div>
                  <span
                    className={classes.delete}
                    onClick={() => {
                      setShowDeleteTemplate(template.id);
                    }}
                  >
                    <AiTwotoneDelete />
                  </span>
                </div>
              );
            })}
            <div className={`popupFormGroup popupFormGroupBtn ${classes.oneBtn}`}>
              <div
                className={`${classes.close} btn`}
                onClick={() => {
                  setShowTemplatesList(false);
                }}
              >
                閉じる
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {showDeleteTemplate ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>テンプレートを削除</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  deleteTemplate(showDeleteTemplate, year);
                  setShowDeleteTemplate(false);
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setShowDeleteTemplate(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
