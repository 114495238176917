import classes from "./MeetingHeader.module.scss";

function MeetingHeader({ meeting }) {
  return (
    <div className={classes.header}>
      <div className={classes.enter}>（一社）富岡青年会議所 {meeting.year}　年度</div>
      <div className={classes.name}>{meeting.name}</div>
      <div className={classes.datePlace}>
        <div className={classes.date}>
          <span>日時：</span>
          {meeting.date}
        </div>
        <div className={classes.place}>
          <span>場所：</span>
          {meeting.place}
        </div>
      </div>
    </div>
  );
}

export default MeetingHeader;
