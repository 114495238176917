import Sidebar from "../components/Admin/Sidebar/Sidebar";
import CreateEditUser from "../components/Admin/CreateEditUser/CreateEditUser";

function CreateEditUserPage() {
  return (
    <div className="adminPages">
      <Sidebar/>
      <div className="content">
        <CreateEditUser />
      </div>
    </div>
  );
}

export default CreateEditUserPage;
