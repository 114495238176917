import RichTextEditor from "react-rte";
import { useState } from "react";
import classes from "./Editor.module.scss";

function Editor({ fieldValue, setFc, params, setEdited }) {
  const [value, setValue] = useState(RichTextEditor.createValueFromString(fieldValue, "html"));
  const onChange = (value) => {
    if (setEdited) {
      setEdited(true);
    }
    setFc(value.toString("html").replace(/<br>/gi, "<br/>"), params);
    setValue(value);
  };
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS",/*'BLOCK_TYPE_DROPDOWN',*/ /*"HISTORY_BUTTONS"*/],
    INLINE_STYLE_BUTTONS: [
      /*{ label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },*/
    ],
    BLOCK_TYPE_DROPDOWN: [
      /*{label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}*/
    ],
    BLOCK_TYPE_BUTTONS: [
      /*{ label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },*/
    ],
  };
  return (
    <div className={classes.editor}>
      <RichTextEditor toolbarConfig={toolbarConfig} value={value} onChange={onChange} />
    </div>
  );
}

export default Editor;
