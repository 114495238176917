import { useEffect, useState } from "react";


export default function Time() {
  const [dateTime, setDateTime] = useState(new Date().toLocaleString());
  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date().toLocaleString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return <div>{dateTime}</div>;
}
