import classes from "./Discussion.module.scss";
import DiscussionElements from "./DiscussionElements/DiscussionElements";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { Context } from "../../../Store/context";
import PDFViewerPage from "./DiscussionComponents/PDFViewerPage/PDFViewerPage";

function Discussion({
  meeting,
  setContent,
  edited,
  users,
  currentUser,
  phase,
  editMode,
  setEditMode,
  discussionId,
  year,
  PDFViewer,
  setPDFViewer,
  setEdited,
  setNotSavedPopupTrigger,
  mini,
}) {
  const { discussionByPhase, fetchDiscussionByPhase, createEditDiscussion, phases } = useContext(Context);
  useEffect(() => {
    fetchDiscussionByPhase(discussionId, phase);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussionId, phase]);
  useEffect(() => {
    setTab(discussionByPhase[discussionByPhase?.length - 1]?.history);
    setCurrentDiscussion(discussionByPhase[discussionByPhase?.length - 1]);
    setLastHistory(discussionByPhase[discussionByPhase?.length - 2]);
    setEditedDiscussion(discussionByPhase[discussionByPhase?.length - 1]);
    setLast(discussionByPhase[discussionByPhase?.length - 1]?.history);
  }, [discussionByPhase]);
  const [currentDiscussion, setCurrentDiscussion] = useState({});
  const [lastHistory, setLastHistory] = useState({});
  const [editedDiscussion, setEditedDiscussion] = useState({});
  const [files, setFiles] = useState([]);
  const [elementIsInEdit, setElementIsInEdit] = useState(false);
  const [elementIsInEditPopup, setElementIsInEditPopup] = useState(false);
  const setTextElement = (value, { section, element, field }) => {
    let sections = editedDiscussion.sections;
    sections[section].elements[element].fields[field].value = value;
    setEditedDiscussion({ ...editedDiscussion, sections: [...sections] });
  };
  /*const setTextElementFile = (value, { section, element }, del, index) => {
    let sections = editedDiscussion.sections;
    if (!sections[section].elements[element].files) {
      sections[section].elements[element].files = [];
    }
    if (index || index === 0) {
      sections[section].elements[element].files[index] = { ...value };
    } else {
      if (del) {
        sections[section].elements[element].files = sections[section].elements[element].files.filter(
          (el) => el.fileName !== value
        );
      } else {
        sections[section].elements[element].files.push(value);
      }
    }
    setEditedDiscussion({ ...editedDiscussion, sections: [...sections] });
  };*/
  const setElementFile = (value, { section, element, field }, del, index) => {
    let sections = editedDiscussion.sections;
    if (!sections[section].elements[element].fields[field].files) {
      sections[section].elements[element].fields[field].files = [];
    }
    if (index || index === 0) {
      sections[section].elements[element].fields[field].files[index] = { ...value };
    } else {
      if (del) {
        sections[section].elements[element].fields[field].files = sections[section].elements[element].fields[
          field
        ].files.filter((el) => el.fileName !== value);
      } else {
        sections[section].elements[element].fields[field].files.push(value);
      }
    }
    setEditedDiscussion({ ...editedDiscussion, sections: [...sections] });
  };
  const setTableRow = (value, { section, element, field }, del, index) => {
    let sections = editedDiscussion.sections;
    if (!sections[section].elements[element].fields[field].values) {
      sections[section].elements[element].fields[field].values = [];
    }
    if (index || index === 0) {
      sections[section].elements[element].fields[field].values[index] = value;
    } else {
      if (!del) {
        sections[section].elements[element].fields[field].values.push(value);
      }
    }
    setEditedDiscussion({ ...editedDiscussion, sections: [...sections] });
  };
  const setChecked = (number) => {
    let checkers = [...editedDiscussion.checkers];
    checkers[number].isChecked = true;
    checkers[number].date = new Date().toLocaleString();
    if (!checkers.filter((el) => !el.isChecked).length) {
      editedDiscussion.isChecked = true;
      setEditedDiscussion({ ...editedDiscussion, checkers: [...checkers] });
    } else {
      setEditedDiscussion({ ...editedDiscussion, checkers: [...checkers] });
    }
    onSaveDiscussion(editedDiscussion.history);
  };
  const onSaveDiscussion = (history) => {
    editedDiscussion.history = history;
    createEditDiscussion(editedDiscussion, meeting.id, files, true);
    setContent({
      type: "discussion",
      name: editedDiscussion.title,
      id: editedDiscussion.id,
      phase: "phase1",
    });
  };
  const [tab, setTab] = useState(0);
  const [last, setLast] = useState(0);
  const linkFunction = (tab, current, lastHistory) => {
    setTab(tab);
    setCurrentDiscussion(current);
    setLastHistory(lastHistory);
    setEditMode(false);
  };
  const [onCreateNewHistory, setOnCreateNewHistory] = useState(0);
  return (
    <>
      {discussionByPhase[0] ? (
        <div className={`${classes.discussion} ${mini ? classes.full : ""}`}>
          <div className={classes.titleWrapper}>
          <div className={`${classes.title} ${discussionByPhase?.isChecked ? "bgGreen" : ""}`}>
            議案名：
            <span>{discussionByPhase[0]?.title}</span>
            <span className={classes.type}>({phases.filter((el) => el.phase === phase)[0]?.name})</span>
          </div>
          <div className={classes.discussionId}>{discussionByPhase[0]?.id}</div>
          </div>
          <div className={classes.tabs}>
            {discussionByPhase?.map((el) => {
              return (
                <div
                  key={el.history}
                  onClick={() => {
                    if (!edited) {
                      if (last !== el.history) setEditMode(false);
                      linkFunction(
                        el.history,
                        discussionByPhase[el.history],
                        discussionByPhase[el.history ? el.history - 1 : 0]
                      );
                    } else {
                      setNotSavedPopupTrigger({
                        func: linkFunction,
                        params: [
                          el.history,
                          discussionByPhase[el.history],
                          discussionByPhase[el.history ? el.history - 1 : 0],
                        ],
                      });
                    }
                  }}
                  className={`${classes.tab} ${tab === el.history ? classes.active : ""}`}
                >
                  履歴番号: {el.history}
                </div>
              );
            })}
          </div>
          {PDFViewer ? (
            <PDFViewerPage
              url={`/discussions/${currentDiscussion.id}/${phase}/${PDFViewer}`}
              setPDFViewer={setPDFViewer}
            />
          ) : (
            <div className={classes.pannels}>
              {discussionByPhase?.map((discussion, i) => (
                <div
                  className={`${classes.pannel} ${tab === discussion.history ? classes.active : ""}`}
                  key={discussion.history}
                >
                  <div className={classes.mainData}>議案情報</div>
                  {(+currentUser?.id === +currentDiscussion?.author ||
                    currentUser.roles.filter((role) => +role.year === +discussion.year)[0]?.premissions[7]) &&
                  last === currentDiscussion?.history ? (
                    editMode ? (
                      <div className={`${classes.setEditBtn} ${classes.saveBtn}`}>
                        <button
                          className={`${classes.btn} btn greenBtn`}
                          onClick={() => {
                            if(elementIsInEdit){
                              setElementIsInEditPopup(true)
                              return
                            }
                            setFiles([]);
                            setEditMode(false);
                            onSaveDiscussion(editedDiscussion.history);
                            setEdited(false);
                            setElementIsInEdit(false)
                          }}
                        >
                          更新
                        </button>
                        <button
                          className="btn"
                          onClick={() => {
                            setElementIsInEdit(false);
                            setEditMode(false);
                            setEdited(false);
                            fetchDiscussionByPhase(discussionId, phase);
                          }}
                        >
                          キャンセル
                        </button>
                      </div>
                    ) : (
                      <div className={`${classes.setEditBtn} ${classes.saveBtn}`}>
                        <button
                          className={`${classes.btn} btn`}
                          onClick={() => {
                            if (last === currentDiscussion.history) setEditMode(true);
                          }}
                        >
                          修正モード
                        </button>
                        {editedDiscussion?.isMovable ? (
                          <button className={`btn`}>持ち越されたため履歴作成不可</button>
                        ) : editedDiscussion?.isChecked ? (
                          <button className={`btn`}>確認済みのため履歴作成不可</button>
                        ) : (
                          <button
                            className={`btn`}
                            onClick={() => {
                              setOnCreateNewHistory(true);
                            }}
                          >
                            今の状態で履歴作成
                          </button>
                        )}
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  <DiscussionElements
                    setChecked={setChecked}
                    currentDiscussion={currentDiscussion}
                    lastHistory={lastHistory}
                    editedDiscussion={editedDiscussion}
                    setTextElement={setTextElement}
                    //setTextElementFile={setTextElementFile}
                    setElementFile={setElementFile}
                    setTableRow={setTableRow}
                    users={users}
                    year={meeting.year}
                    currentUser={currentUser}
                    editMode={editMode}
                    setFiles={setFiles}
                    files={files}
                    setPDFViewer={setPDFViewer}
                    setEdited={setEdited}
                    setOnCreateNewHistory={setOnCreateNewHistory}
                    pannelNumber={i}
                    phase={phase}
                    elementIsInEdit={elementIsInEdit}
                    setElementIsInEdit={setElementIsInEdit}
                  />
                  {editMode ? <div className={classes.bottomSpace}></div> : ""}
                </div>
              ))}
            </div>
          )}
          {edited ? <div className={classes.saveBtn} onClick={onSaveDiscussion}></div> : ""}
        </div>
      ) : null}
      {onCreateNewHistory ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>今の状態で履歴作成します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  setEditMode(false);
                  onSaveDiscussion(editedDiscussion.history + 1);
                  setOnCreateNewHistory(false);
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setOnCreateNewHistory(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {elementIsInEditPopup ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>編集中の表形式があります</div>
            <div className={``}>
              <div
                className={`${classes.btn} btn`}
                onClick={() => {
                  setElementIsInEditPopup(false);
                }}
              >
                閉じる
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Discussion;
