import Sidebar from "../components/Admin/Sidebar/Sidebar";
import Meetings from "../components/Admin/Meetings/Meetings";

function MeetingsPage() {
  return (
    <div className="adminPages">
      <Sidebar />
      <Meetings/>
    </div>
  );
}

export default MeetingsPage;
