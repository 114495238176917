import classes from "./Section.module.scss";
import { useState } from "react";
import { TiEdit } from "react-icons/ti";
import { AiTwotoneDelete } from "react-icons/ai";
import Element from "./Element/Element";

export default function Section({
  section,
  sectionNumber,
  saveElement,
  saveElementField,
  discussionForm,
  setDiscussionForm,
  setResponse,
  noEdit,
}) {
  const [addElement, setAddElement] = useState(false);
  const [newElement, setNewElement] = useState({ name: "", fields: [] });
  const [changeName, setChangeName] = useState(false);
  const [newName, setnewName] = useState(section.name);
  const [deleteSection, setDeleteSection] = useState(false);
  return (
    <div className={classes.section}>
      <div className={classes.sectionName}>
        {section.name}
        {noEdit ? (
          ""
        ) : (
          <>
            <span
              onClick={() => {
                setChangeName(true);
              }}
            >
              <TiEdit />
            </span>
            <span
              className={classes.delete}
              onClick={() => {
                setDeleteSection(sectionNumber);
              }}
            >
              <AiTwotoneDelete />
            </span>
          </>
        )}
      </div>
      {section.elements.map((el, i) => (
        <Element
          element={el}
          number={i + 1 + ")"}
          key={el.name}
          saveElement={saveElement}
          saveElementField={saveElementField}
          sectionNumber={sectionNumber}
          elementNumber={i}
          discussionForm={discussionForm}
          setDiscussionForm={setDiscussionForm}
          setResponse={setResponse}
          noEdit={noEdit}
        />
      ))}
      {noEdit ? (
        ""
      ) : (
        <div
          className={`${classes.btns} btn`}
          onClick={() => {
            setAddElement(true);
          }}
        >
          詳細項目
        </div>
      )}
      {changeName ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>編集</div>
            <div className={`popupFormGroup`}>
              <label htmlFor="">項目名</label>
              <input
                className="input"
                type="text"
                value={newName}
                onChange={(e) => {
                  setnewName(e.target.value);
                }}
              />
            </div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <span
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  setChangeName(false);
                  section.name = newName;
                }}
              >
                一時保存
              </span>
              <span
                className={`${classes.btns} btn `}
                onClick={() => {
                  setChangeName(false);
                }}
              >
                キャンセル
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {addElement ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>詳細項目 追加</div>
            <div className={`popupFormGroup`}>
              <label>表題</label>
              <input
                required
                className="input"
                type="text"
                value={newElement?.name}
                onChange={(e) => {
                  setNewElement({ ...newElement, name: e.target.value });
                }}
              />
            </div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  setAddElement(false);
                  setNewElement({ name: "", fields: [] });
                  section.elements.push(newElement);
                }}
              >
                一時保存
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setAddElement(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {deleteSection || deleteSection === 0 ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>項目を削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  discussionForm.sections.splice(deleteSection, 1);
                  setDiscussionForm({ ...discussionForm });
                  setResponse({ text: "項目を削除しました。" });
                  setDeleteSection(false);
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setDeleteSection(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
