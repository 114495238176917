function NotSavedPopup({ notSavedPopupTrigger, setNotSavedPopupTrigger, setEdited }) {
  return (
    <div className={`popupWrapper`}>
      <div className="popup">
        <div className={`popupTitle`}>
          <div>変更されています</div>
          <div>更新する場合、キャンセルして</div>
          <div>更新ボタンを押してください</div>
        </div>
        <div className={`popupFormGroup popupFormGroupBtn`}>
          <button
            className="btn greenBtn"
            onClick={() => {
              notSavedPopupTrigger.func(...notSavedPopupTrigger.params);
              setNotSavedPopupTrigger(null);
              setEdited(false);
            }}
          >
            ok
          </button>
          <button
            className="btn"
            onClick={() => {
              setNotSavedPopupTrigger(null);
            }}
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  );
}

export default NotSavedPopup;
