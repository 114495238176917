import classes from "./Header.module.scss";
import { useState } from "react";
import Compare from "../../DiscussionComponents/Compare/Compare";

function Header({
  setChecked,
  currentDiscussion,
  lastHistory,
  users,
  currentUser,
  year,
  currentHistory,
  setOnCreateNewHistory,
  pannelNumber
}) {
  const [popup, setPopup] = useState(-1);
  return currentDiscussion ? (
    <div className={classes.header} id={`議案情報${pannelNumber}`}>
      <div className={classes.headerItems}>
        <div className={classes.topItems}>
          <div className={classes.item}>
            <div className={classes.itemName}>事業名</div>
            <div className={classes.itemValue}>
              :
              <span>
                {currentDiscussion?.name ? (
                  <Compare
                    prev={lastHistory?.name}
                    current={currentDiscussion?.name}
                  />
                ) : null}
              </span>
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.itemName}>業務分掌</div>
            <div className={classes.itemValue}>
              <div className={classes.itemValue}>
                :
                <span>
                  {currentDiscussion?.about ? (
                    <Compare
                      prev={lastHistory?.about}
                      current={currentDiscussion?.about}
                    />
                  ) : null}
                </span>
              </div>
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.itemName}>委員会名</div>
            <div className={classes.itemValue}>
              <div className={classes.itemValue}>
                :
                <span>
                  {currentDiscussion?.department ? (
                    <Compare
                      prev={lastHistory?.department}
                      current={currentDiscussion?.department}
                    />
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.bottomItems}>
          <div className={`${classes.bottomItem} ${classes.author}`}>
            <div className={classes.item}>
              <div className={classes.itemName}>文書作成者名・役職</div>
              <div className={classes.itemValue}>
                <div className={classes.itemValue}>
                  :
                  <span>
                    {
                      users.filter(
                        (user) => user.id === +currentDiscussion.author
                      )[0]?.name
                    }
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.item}>
                <div className={`${classes.itemName}  ${classes.dates}`}>
                  作成日時
                </div>
                <div className={classes.itemValue}>
                  :<span>{currentDiscussion.added}</span>
                </div>
              </div>
              <div className={classes.item}>
                <div className={`${classes.itemName}  ${classes.dates}`}>
                  最終更新日時
                </div>
                <div className={classes.itemValue}>
                  :<span>{currentDiscussion.edited}</span>
                </div>
              </div>
            </div>
          </div>
          {currentDiscussion.checkers?.map((checker, i) => {
            return (
              <div className={classes.bottomItem} key={checker.checker}>
                <div className={classes.item}>
                  <div className={`${classes.itemName}`}>
                    {
                      users
                        .filter((user) => user.id === +checker.checker)[0]
                        ?.userRoles.roles.filter((el) => +el.year === year)[0]
                        ?.position
                    }
                    {
                      users
                        .filter((user) => user.id === +checker.checker)[0]
                        ?.userRoles.roles.filter((el) => el.year === year)[0]
                        ?.position
                    }
                    確認日
                    {/*console.log(
                      users
                        .filter((user) => user.id === +checker.checker)[0]
                        ?.userRoles.roles.filter((el) => el.year === year)[0]
                        ?.position
                    )*/}
                  </div>
                  <div className={classes.itemValue}>
                    :
                    <span>
                      {
                        users.filter((user) => user.id === +checker.checker)[0]
                          ?.name
                      }
                    </span>
                  </div>
                </div>
                <div className={classes.item}>
                  <div className={`${classes.itemName}  ${classes.dates}`}>
                    確認日
                  </div>
                  <div className={classes.itemValue}>
                    :
                    <span
                      className={`${
                        checker.isChecked
                          ? classes.green + " " + classes.date
                          : classes.red
                      }`}
                    >
                      {checker.date} {checker.isChecked ? "" : "確認期限"}
                    </span>
                    {checker.isChecked ? (
                      <>
                        <span className={classes.green}>確認</span>
                        {/* currentUser.id === +checker.checker &&
                      currentHistory === currentDiscussion?.history?
                        <button
                          className={`btn`}
                          onClick={() => {
                            setOnCreateNewHistory(true);
                          }}
                        >
                          今の状態で履歴作成 ???
                        </button>:""*/}
                      </>
                    ) : currentUser.id === +checker.checker &&
                      currentHistory === currentDiscussion?.history ? (
                      <>
                        <span className={classes.red}>未確認</span>
                        <button
                          className={`btn`}
                          onClick={() => {
                            setPopup(i);
                          }}
                        >
                          確認
                        </button>
                      </>
                    ) : (
                      <span className={classes.red}>未確認</span>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {popup > -1 ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>確認済みにしますか？</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <button
                className="btn greenBtn"
                onClick={() => {
                  setChecked(popup);
                  setPopup(-1);
                }}
              >
                確認
              </button>
              <button
                className="btn"
                onClick={() => {
                  setPopup(-1);
                }}
              >
                キャンセル
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
}

export default Header;
