import Sidebar from "../components/Admin/Sidebar/Sidebar";
import MainDocuments from "../components/Admin/MainDocuments/MainDocuments";

function MainDocumentsPage() {
  return (
    <div className="adminPages">
      <Sidebar />
      <MainDocuments/>
    </div>
  );
}

export default MainDocumentsPage;
