import classes from "./Text.module.scss";
import Editor from "../Editor/Editor";
import Compare from "../Compare/Compare";

function Text({ editMode, current, prev, setTextElement, params, setEdited, id }) {
  return (
    <div className={classes.textField}>
      {editMode ? (
        <Editor fieldValue={current || "該当無し"} setFc={setTextElement} params={params} setEdited={setEdited} />
      ) : (
        <div className={classes.itemValue}>
          <span>
            <Compare prev={prev} current={current || "該当無し"} id={id}/>
          </span>
        </div>
      )}
    </div>
  );
}

export default Text;
