import classes from "./MeetingContent.module.scss";

import MeetingHeader from "./MeetingHeader/MeetingHeader";
import MeetingProgram from "./MeetingProgram/MeetingProgram";

function MeetingContent({ meeting, setContent, setHistoryNumber, mini }) {
  return (
    <div className={`${classes.content} ${mini?classes.full:""}`}>
      <MeetingHeader meeting={meeting} />
      <MeetingProgram meeting={meeting} setContent={setContent} setHistoryNumber={setHistoryNumber}/>
    </div>
  );
}

export default MeetingContent;
