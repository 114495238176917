import { Link } from "react-router-dom";
import classes from "./Table.module.scss";

function Table({ entities, cols, currentUser, jpcols, disableEnable, year, all, search }) {
  return (
    <table className={classes.table}>
      <tbody>
        <tr className={`${classes.row} ${classes.title}`}>
          {jpcols.map((col) => (
            <td key={col} className={`${classes.col}`}>
              {col}
            </td>
          ))}
          <td className={`${classes.col} ${classes.btnCol}`}></td>
          <td className={`${classes.col} ${classes.btnCol}`}></td>
        </tr>
        {entities
          ?.filter((el) => (all ? true : el.active))
          ?.filter((el) => (search !== "" ? el.name.toString().toLowerCase().includes(search?.toLowerCase()) : true))
          .map((entity, i) => {
            let show = currentUser.isAdmin || !entity?.userRoles?.admin;
            if (show) {
              return (
                <tr className={`${classes.row} ${classes.odd}`} key={entity.id}>
                  {cols.map((col) => {
                    return (
                      <td className={`${classes.col}`} key={col}>
                        {col === "createdAt" || col === "updatedAt"
                          ? new Date(entity[col]).toLocaleString()
                          : col === "active"
                          ? entity[col]
                            ? ""
                            : "無効"
                          : entity[col]}
                      </td>
                    );
                  })}
                  <td className={`${classes.col} ${classes.btnCol}`}>
                    <Link to={"" + entity.id}>
                      <div className={` btn`}>編集</div>
                    </Link>
                  </td>
                  <td
                    className={`${classes.col} ${classes.btnCol}`}
                    onClick={() => {
                      disableEnable({ ...entity, year });
                    }}
                  >
                    <div className={`redBtn btn`}>{entity.active ? "無効化" : "復活"}</div>
                  </td>
                </tr>
              );
            } else {
              return null;
            }
          })}
      </tbody>
    </table>
  );
}

export default Table;
