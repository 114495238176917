import classes from "./ChangePassPopup.module.scss";
import { useState } from "react";

export default function ChangePassPopup({ changePassword, setShowPopup, id, year }) {
  const [password, setPassword] = useState("");
  const onChangeInput = (event) => {
    setPassword(event.target.value);
  };
  const onClose = () => {
    setShowPopup(false);
  };
  const onChangePassword = () => {
    changePassword("user", password, id, year);
    setShowPopup(false);
    setPassword("");
  };
  return (
    <div className={`popupWrapper`}>
      <div className={`popup ${classes.popup}`}>
        <div className={`popupTitle`}>パスワード再発行</div>
        <div className={`popupFormGroup`}>
          <label htmlFor="">
            新しいパスワード
            <br />
            を入力してください
          </label>
          <input className="input" type="text" value={password} onChange={onChangeInput} />
        </div>
        <div className={`popupFormGroup popupFormGroupBtn`}>
          <div className={`btn greenBtn`} onClick={onChangePassword}>
            更新
          </div>
          <div className={`btn`} onClick={onClose}>
            キャンセル
          </div>
        </div>
      </div>
    </div>
  );
}
