import { Route, Routes } from "react-router-dom";
import "./App.scss";
import { useContext, useState, useEffect } from "react";
import { Context } from "./Store/context";
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
import InfoPopup from "./components/InfoPopup/InfoPopup";
import UsersPage from "./pages/UsersPage";
import Header from "./components/Header/Header";
import CreateEditUserPage from "./pages/CreateEditUserPage";
import MainDocumentsPage from "./pages/MainDocumentsPage";
import MeetingsPage from "./pages/MeetingsPage";
import CreateEditMeetingPage from "./pages/CreateEditMeetingPage";
import CreateEditDiscussionPage from "./pages/CreateEditDiscussionPage";
import MeetingPage from "./pages/MeetingPage";
import NotFound from "./pages/NotFound";

function App() {
  const { auth, check, response, setResponse, admin } = useContext(Context);
  const [isLoadin, setIsLoadin] = useState(true);
  useEffect(() => {
    check().finally(() => setIsLoadin(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/diff_match_patch_uncompressed.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="App">
      {isLoadin ? (
        <div className="loader">Loading....</div>
      ) : (
        <>
          {auth ? (
            <>
              <Header />
              {/*<Sidebar />*/}
              {response?.text !== "" ? <InfoPopup response={response} setResponse={setResponse} /> : ""}
            </>
          ) : (
            ""
          )}
          <Routes>
            {auth ? (
              <>
                <Route exec path="/" element={<HomePage />} />
                <Route path="/meeting/:id" element={<MeetingPage />} />
                {/*<Route exec path="/createMeeting" element={<CreateMeetingPage />} />
                 */}
                {admin ? (
                  <>
                    <Route exec path="/admin/users" element={<UsersPage />} />
                    <Route exec path="/admin/users/:id" element={<CreateEditUserPage />} />
                    <Route exec path="/admin/mainDocuments" element={<MainDocumentsPage />} />
                    <Route exec path="/admin/meetings" element={<MeetingsPage />} />
                    <Route path="/admin/meetings/:year/:id" element={<CreateEditMeetingPage />} />
                    <Route path="/admin/discussions/:id" element={<CreateEditDiscussionPage />} />
                  </>
                ) : null}
                <Route exac path="/*" element={<NotFound />} />
              </>
            ) : (
              <>
                <Route exac path="/*" element={<AuthPage />} />
              </>
            )}
          </Routes>
        </>
      )}
    </div>
  );
}

export default App;
