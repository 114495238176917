import { Link } from "react-router-dom";
import classes from "./Filters.module.scss";

function Filters({ all, setAll, search, setSearch }) {
  return (
    <div className={classes.wrapper}>
      <Link to={"/admin/users/0"}>
        <div className={`${classes.btn} btn`}>追加</div>
      </Link>
      <div className={classes.filters}>
        <div>
          <input
          className="input"
          placeholder="氏名検索"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div>
          <label>無効を表示</label>
          <input
            type="checkbox"
            checked={all}
            onChange={() => {
              setAll(!all);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Filters;
