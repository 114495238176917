import classes from "./DiscussionElements.module.scss";
import Header from "./Header/Header";
import DiscussionMap from "./DiscussionMap/DiscussionMap";
import Text from "../DiscussionComponents/Text/Text";
import Table from "../DiscussionComponents/Table/Table";
import File from "../DiscussionComponents/File/File";
import { useState } from "react";

function DiscussionElements({
  setChecked,
  currentDiscussion,
  lastHistory,
  users,
  currentUser,
  year,
  setTextElement,
  //setTextElementFile,
  setElementFile,
  setTableRow,
  editMode,
  setFiles,
  files,
  setPDFViewer,
  setEdited,
  editedDiscussion,
  setOnCreateNewHistory,
  pannelNumber,
  phase,
  elementIsInEdit,
  setElementIsInEdit,
}) {
  const myScrollTo = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div className={classes.actual}>
      <Header
        setChecked={setChecked}
        currentDiscussion={currentDiscussion}
        lastHistory={lastHistory}
        users={users}
        year={year}
        currentUser={currentUser}
        currentHistory={editedDiscussion?.history}
        setOnCreateNewHistory={setOnCreateNewHistory}
        pannelNumber={pannelNumber}
      />
      <DiscussionMap currentDiscussion={currentDiscussion} myScrollTo={myScrollTo} />
      {currentDiscussion?.sections?.map((section, s) => {
        return (
          < div key={section.name}>
            <div className={classes.section} id={section.name + pannelNumber}>
              <div className={classes.topLine}></div>
              <div className={classes.title}>{section.name}</div>
              {section?.elements?.map((el, e) => (
                <div className={classes.item} key={el.name + e}>
                  <div className={classes.itemTitle}>
                    {e + 1}．<span>{el.name}</span>
                  </div>
                  <div className={classes.itemValue}>
                    {el.fields?.map((field, f) => {
                      if (field.type === "text") {
                        return (
                          <Text
                            editMode={editMode}
                            current={field.value}
                            //prev={lastHistory?.sections[s]?.elements[e]?.value}
                            prev={lastHistory?.sections[s]?.elements[e]?.fields[f]?.value}
                            setTextElement={setTextElement}
                            params={{ section: s, element: e, field: f }}
                            setEdited={setEdited}
                            id={e + 1}
                          />
                        );
                      } else if (field.type === "File") {
                        return (
                          <File
                            setFiles={setFiles}
                            files={files}
                            editMode={editMode}
                            current={field}
                            prev={lastHistory?.sections[s]?.elements[e]?.fields[f]}
                            setTextElement={setTextElement}
                            //setTextElementFile={setTextElementFile}
                            setElementFile={setElementFile}
                            params={{ section: s, element: e, field: f }}
                            history={currentDiscussion.history}
                            setPDFViewer={setPDFViewer}
                            setEdited={setEdited}
                            phase={phase}
                            key={field.value + f}
                          />
                        );
                      } else {
                        return (
                          <Table
                            setFiles={setFiles}
                            files={files}
                            current={field}
                            editMode={editMode}
                            prev={lastHistory?.sections[s]?.elements[e]?.fields[f]}
                            setTableRow={setTableRow}
                            params={{ section: s, element: e, field: f }}
                            history={currentDiscussion.history}
                            setPDFViewer={setPDFViewer}
                            setEdited={setEdited}
                            phase={phase}
                            elementIsInEdit={elementIsInEdit}
                            setElementIsInEdit={setElementIsInEdit}
                            key={field.value + f}
                          />
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
            <DiscussionMap currentDiscussion={currentDiscussion} myScrollTo={myScrollTo} />
          </div>
        );
      })}
    </div>
  );
}

export default DiscussionElements;
