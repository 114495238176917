import { useState } from "react";
import classes from "./Col.module.scss";

function Col({ col, colNumberInRow, current, newRow, setNewRow, setFile, file, files, history, value, phase }) {
  return (
    <td className={classes.formGroup} key={col.value}>
      {!current.colums[colNumberInRow].file && !current.colums[colNumberInRow].url ? (
        <>
          <input
            placeholder="ここに入力"
            required
            className="input"
            type="text"
            value={newRow.colums[colNumberInRow].fieldValue}
            onChange={(e) => {
              let colums = [...newRow.colums];
              colums[colNumberInRow].fieldValue = e.target.value;
              // 新規
              if (colums[colNumberInRow].currentHistory === undefined) {
                colums[colNumberInRow].currentHistory = history;
                colums[colNumberInRow].currentPhase = phase;
                colums[colNumberInRow].isUpdate = undefined; 
              }
              setNewRow({ ...newRow, colums });
            }}
          />
        </>
      ) : (
        <>
          <input
            placeholder="表示名を入力"
            required
            className="input"
            type="text"
            value={newRow.colums[colNumberInRow].urlFileTitle}
            onChange={(e) => {
              let colums = [...newRow.colums];
              colums[colNumberInRow].urlFileTitle = e.target.value;
              // 新規
              if (colums[colNumberInRow].currentHistory === undefined) {
                colums[colNumberInRow].currentHistory = history;
                colums[colNumberInRow].currentPhase = phase;
                colums[colNumberInRow].isUpdate = undefined; 
              }
              setNewRow({ ...newRow, colums });
            }}
          />
          {current.colums[colNumberInRow].url && !newRow.colums[colNumberInRow].fileName ? (
            <input
              placeholder="URL入力"
              required
              className="input"
              type="text"
              value={newRow.colums[colNumberInRow].urlValue}
              onChange={(e) => {
                let colums = [...newRow.colums];
                colums[colNumberInRow].urlValue = e.target.value;
                colums[colNumberInRow].fileName = null;
                // 新規
                if (colums[colNumberInRow].currentHistory === undefined) {
                  colums[colNumberInRow].currentHistory = history;
                  colums[colNumberInRow].currentPhase = phase;
                  colums[colNumberInRow].isUpdate = undefined; 
                // 更新
                } else {
                  colums[colNumberInRow].isUpdate = true;
                  colums[colNumberInRow].currentHistory = history;
                }
                setNewRow({ ...newRow, colums });
              }}
            />
          ) : null}
          {current.colums[colNumberInRow].file && !newRow.colums[colNumberInRow].urlValue ? (
            <div style={{ display: "flex", flexDirection: "column", marginTop:"10px" }}>
              {newRow.colums[colNumberInRow].fileName?
              <div style={{display:"flex", margin:"5px 0"}}>
                <label style={{color:"black",marginRight:"5px"}}>現保存ファイル: </label>
                <div className={newRow.colums.currentHistory === history && newRow.colums.currentHistory > 0 && newRow.colums.currentPhase === phase ? classes.red : ""}>
                  <div className={classes.fileName}>{newRow.colums[colNumberInRow].fileName}</div>
                </div>
              </div>:""}
              <input
                required
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  let colums = [...newRow.colums];
                  colums[colNumberInRow].fileName = history + "_" + e.target.files[0]?.name;
                  colums[colNumberInRow].urlValue = null;
                  // 新規
                  if (colums[colNumberInRow].currentHistory === undefined) {
                    colums[colNumberInRow].currentHistory = history;
                    colums[colNumberInRow].currentPhase = phase;
                    colums[colNumberInRow].isUpdate = undefined; 
                  // 更新
                  } else {
                    colums[colNumberInRow].isUpdate = true;
                    colums[colNumberInRow].currentHistory = history;
                  }
                  setNewRow({ ...newRow, colums });
                  setFile({
                    ...file,
                    fileName: history + "_" + e.target.files[0]?.name,
                    file: {
                      file: e.target.files[0],
                      fileSrc: URL.createObjectURL(e.target.files[0]),
                      number: files.length,
                    },
                  });
                }}
              />
            </div>
          ) : null}
        </>
      )}
    </td>
  );
}

export default Col;
