import classes from "./ChooseYear.module.scss";

function ChooseYear({ meetings, setChooseYear, setYear }) {
  let years = [];
  meetings.forEach((element) => {
    if (!years.filter((year) => year === element.year)[0]) {
      years.push(element.year);
    }
  });
  
  years.sort((a, b) => a - b);
  years.push(+years[years.length-1]+1)
  return (
    <div className={classes.table}>
      {years.map((year) => {
        return (
          <div
            className={classes.tableItem}
            onClick={() => {
              setChooseYear(false);
              setYear(year);
            }}
          >
            {year}
          </div>
        );
      })}
    </div>
  );
}

export default ChooseYear;
