import classes from "./MeetingProgram.module.scss";
import parse from "html-react-parser";

function MeetingProgram({ meeting, setContent, setHistoryNumber }) {
  meeting?.data?.discussions?.sort((a, b) => a.number - b.number);
  return (
    <div className={classes.program}>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>1.</span>開会
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>2.</span>JCIクリード朗読　JCIミッション朗読　JCIビジョン朗読
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>3.</span>JC宣言及び綱領朗読　関東地区宣言朗読　LOMスローガン朗読
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>4.</span>点呼
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>5.</span>理事長挨拶
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>7.</span>資格審査報告
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>8.</span>資料の確認
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>9.</span>前回議事録の確認
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>10.</span>議事録作成者及び署名人の指名
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>11.</span>確認事項
        </div>
        <div className={classes.programItemContent}>
          <table>
            <tbody>
              {meeting?.data?.discussions
                ?.filter((discussion) => discussion.type === "確認" && discussion.historyNumber === "0")
                .map((discussion) => {
                  return (
                    <tr key={discussion.title}>
                      <td className={classes.number}>({discussion.number})</td>
                      <td
                        className={classes.link}
                        onClick={() => {
                          setContent({ type: "discussion", name: discussion.title });
                          setHistoryNumber(meeting?.data?.discussions?.filter((discussion2) => discussion2.type === "確認" && discussion.title ===discussion2.title).sort((a, b) => b.historyNumber - a.historyNumber)[0]?.historyNumber)
                        }}
                      >
                        {parse(discussion.title)}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td className={classes.number}>
                  (
                  {meeting?.data?.discussions?.filter(
                    (discussion) => discussion.type === "確認" && discussion.historyNumber === "0"
                  ).length + 1}
                  )
                </td>
                <td>その他</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>12.</span>審議事項
        </div>
        <div className={classes.programItemContent}>
          <table>
            <tbody>
              {meeting?.data?.discussions
                ?.filter((discussion) => discussion.type === "審議" && discussion.historyNumber === "0")
                .map((discussion) => {
                  return (
                    <tr key={discussion.title}>
                      <td className={classes.number}>({discussion.number})</td>
                      <td
                        className={classes.link}
                        onClick={() => {
                          setContent({ type: "discussion", name: discussion.title });
                        }}
                      >
                        {parse(discussion.title)}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td className={classes.number}>
                  (
                  {meeting?.data?.discussions?.filter(
                    (discussion) => discussion.type === "審議" && discussion.historyNumber === "0"
                  ).length + 1}
                  )
                </td>
                <td>その他</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>13.</span>協議事項
        </div>
        <div className={classes.programItemContent}>
          <table>
            <tbody>
              {meeting?.data?.discussions
                ?.filter((discussion) => discussion.type === "協議" && discussion.historyNumber === "0")
                .map((discussion) => {
                  return (
                    <tr key={discussion.title}>
                      <td className={classes.number}>({discussion.number})</td>
                      <td
                        className={classes.link}
                        onClick={() => {
                          setContent({ type: "discussion", name: discussion.title });
                        }}
                      >
                        {parse(discussion.title)}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td className={classes.number}>
                  (
                  {meeting?.data?.discussions?.filter(
                    (discussion) => discussion.type === "協議" && discussion.historyNumber === "0"
                  ).length + 1}
                  )
                </td>
                <td>その他</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>14.</span>報告事項
        </div>
        <div className={classes.programItemContent}>
          <table>
            <tbody>
              {meeting?.data?.discussions
                ?.filter((discussion) => discussion.type === "報告")
                .map((discussion) => {
                  return (
                    <tr key={discussion.title}>
                      <td className={classes.number}>({discussion.number})</td>
                      <td> {parse(discussion.title)}</td>
                    </tr>
                  );
                })}
              <tr>
                <td className={classes.number}>
                  ({meeting?.data?.discussions?.filter((discussion) => discussion.type === "報告").length + 1})
                </td>
                <td>その他</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>15.</span>事務局からのお知らせ
        </div>
        <div className={classes.programItemContent}>
          <div className={classes.notice}>
            <div className={classes.noticeItem}>
              <div className={classes.noticeItemTitle}>・第9回執行部会議</div>
              <div className={classes.noticeItemContent}>
                開 催 日 　　　2022年9月7日（水）19：00～
                <br />
                会 場 富 　　　岡商工会議所会館　２階中会議室
                <br />
                資料締切　　　2022年9月2日（金）21：00まで
                <br />
              </div>
            </div>
            <div className={classes.noticeItem}>
              <div className={classes.noticeItemTitle}>・第9回執行部会議</div>
              <div className={classes.noticeItemContent}>
                開 催 日 　　　2022年9月7日（水）19：00～
                <br />
                会 場 富 　　　富岡商工会議所会館　２階中会議室
                <br />
                資料締切　　　2022年9月2日（金）21：00まで
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>16.</span>講評
        </div>
      </div>
      <div className={classes.programItem}>
        <div className={classes.programItemTitle}>
          <span>17.</span>閉会
        </div>
      </div>
    </div>
  );
}

export default MeetingProgram;
