import { useContext, useState } from "react";
import { Context } from "../../Store/context";
import classes from "./Header.module.scss";
import { IoMdMail } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import Time from "./Time/Time";

export default function Header() {
  const { currentUser, logout, year } = useContext(Context);
  let location = useLocation();
  let adminMode = location.pathname.includes("admin");
  return (
    <div className={classes.header}>
      <Link to={"/"} className={classes.logo}>
        <img src="/images/JCIlogo_trans.png" alt="logo" />
      </Link>
      <div className={classes.right}>
        <div className={classes.welcome}>
          <Time />
          <div>ようこそ {currentUser.name}</div>
        </div>
        <div className={classes.message}>
          {/* <IoMdMail /> */}
        </div>
        {currentUser.isAdmin ||  currentUser?.roles?.filter((el) => +el.year === +year)[0]?.premissions.filter(el=>el)[0] ? (
          adminMode ? (
            <Link to={"/"}>
              <button className={`${classes.adminBtn} btn`}>会議参照</button>
            </Link>
          ) : (
            <Link to={"/admin/meetings"}>
              <button className={`${classes.adminBtn} btn`}>管理モードへ</button>
            </Link>
          )
        ) : null}
        <Link to="/">
          <button className="btn" onClick={logout}>
            ログアウト
          </button>
        </Link>
      </div>
    </div>
  );
}
