import { Link } from "react-router-dom";
import classes from "./HomePageContent.module.scss";

function HomePageContent({ meetings, year }) {
  return (
    <div className={classes.table}>
      {meetings
        ?.filter((meeting) => +meeting.year === +year)
        .filter((meeting) => meeting.active)
        .sort((a, b) => b.number - a.number)
        .map((meeting) => {
          return (
            <div className={classes.tableItemWrapper} key={meeting.name}>
              <Link to={`/meeting/${meeting.id}`} className={classes.tableItem} key={meeting.id}>
                <div className={classes.name}>{meeting.name}</div>
                <div className={classes.metaData}>
                  <div className={classes.date}>日時：{meeting.date}</div>
                  <div className={classes.place}>場所：{meeting.place}</div>
                </div>
              </Link>
            </div>
          );
        })}
    </div>
  );
}

export default HomePageContent;
