import { Link } from "react-router-dom";
import classes from "./Sidebar.module.scss";
import { FiUsers } from "react-icons/fi";
import { BsCalendar3 } from "react-icons/bs";
import { GoCommentDiscussion } from "react-icons/go";
import { useContext } from "react";
import { Context } from "../../../Store/context";

function Sidebar() {
  const { currentUser } = useContext(Context);
  let director =
    currentUser.roles
      .map((el) => {
        return el.premissions[0] || el.premissions[1];
      })
      .filter((el) => el)[0] || false;
  let userManager =
    currentUser.roles
      .map((el) => {
        return el.premissions[2];
      })
      .filter((el) => el)[0] || false;
  return (
    <div className={classes.sidebar}>
      {userManager || currentUser.isAdmin ? (
        <Link to="/admin/users">
          <div>
            <FiUsers />
            会員
          </div>
        </Link>
      ) : (
        ""
      )}
      {director || currentUser.isAdmin ? (
        <Link to="/admin/mainDocuments">
          <div className={classes.df}>
            <BsCalendar3 />
            <div>通年資料</div>
          </div>
        </Link>
      ) : null}
      <Link to="/admin/meetings">
        <div>
          <GoCommentDiscussion />
          会議
        </div>
      </Link>
    </div>
  );
}

export default Sidebar;
