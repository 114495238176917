import { Link } from "react-router-dom";
import classes from "./InfoPopup.module.scss";

export default function InfoPopup({ response, setResponse }) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.popup}>
        <div className={`${classes.text} ${response?.text?.includes("エラー") ? classes.red : ""}`}>{response.text}</div>
        {response?.link ? (
          <Link to={response?.link}>
            <div
              className={`${classes.btns} btn`}
              onClick={() => {
                setResponse({ text: "", link: "" });
              }}
            >
              Ok
            </div>
          </Link>
        ) : (
          <div
            className={`${classes.btns} btn`}
            onClick={() => {
              setResponse({ text: "", link: "" });
            }}
          >
            Ok
          </div>
        )}
      </div>
    </div>
  );
}
