import classes from "./CreateEditMeeting.module.scss";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../../../Store/context";
import { MdOutlineChangeCircle, MdOutlineClose } from "react-icons/md";
import { AiTwotoneDelete } from "react-icons/ai";
import PDFViewer from "./PDFViewer/PDFViewer";

export default function CreateEditMeeting() {
  const { fetchMeetings, createEditMeeting, meetings } = useContext(Context);
  const { id, year } = useParams();
  const [num, setNum] = useState(meetings.filter((el)=>el.year === year).length !== 0 ? meetings.filter((el)=>el.year === year)[meetings.filter((el)=>el.year === year).length-1].number+1 : 1);
  useEffect(() => {
    fetchMeetings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [newPrevious, setNewPrevious] = useState(false);
  const [newCurrent, setNewCurrent] = useState(false);
  let meeting = +id === 0 ? null : meetings?.filter((meeting) => +meeting.id === +id)[0];
  useEffect(() => {
    setMeetingForm({
      id: meeting?.id,
      name: meeting?.name || "",
      date: meeting?.date || "",
      year: meeting?.year || year,
      place: meeting?.place || "",
      previous: meeting?.previous || "",
      current: meeting?.current || "",
      previousFile: null,
      currentFile: null,
      discussions: meeting?.discussions || [],
      active:meeting?.active,
      number: meeting?.number || num,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting]);
  const [meetingForm, setMeetingForm] = useState({
    id: meeting?.id,
    name: meeting?.name || "",
    date: meeting?.date || "",
    year: meeting?.year || year,
    place: meeting?.place || "",
    previous: meeting?.previous || "",
    current: meeting?.current || "",
    previousFile: null,
    currentFile: null,
    discussions: meeting?.discussions || [],
    active:meeting?.active,
  });
  const onSubmit = (e) => {
    e.preventDefault();
    createEditMeeting(meetingForm);
  };
  const [PDF, setPDF] = useState(false);
  const [url, setUrl] = useState("");
  const [deleteFile, setDeleteFile] = useState(false);
  return (
    <div className={classes.wrapper}>
      {PDF ? (
        <PDFViewer url={url} setPDF={setPDF} />
      ) : (
        <form onSubmit={onSubmit}>
          <div className={classes.formGroup}>
            <label>名前</label>
            <input
              required
              className={`input ${classes.input}`}
              type="text"
              value={meetingForm.name}
              onChange={(e) => {
                setMeetingForm({ ...meetingForm, name: e.target.value });
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <label>開催日</label>
            <input
              required
              className={`input ${classes.input}`}
              type="text"
              value={meetingForm.date}
              onChange={(e) => {
                setMeetingForm({ ...meetingForm, date: e.target.value });
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <label>会場</label>
            <input
              required
              className={`input ${classes.input}`}
              type="text"
              value={meetingForm.place}
              onChange={(e) => {
                setMeetingForm({ ...meetingForm, place: e.target.value });
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <label>会議を公開</label>
            <input
              className={`input ${classes.input}`}
              type="checkbox"
              checked={meetingForm.active}
              onChange={(e) => {
                setMeetingForm({ ...meetingForm, active: !meetingForm.active });
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <label>議事録</label>
            {+id && meetingForm.previous ? (
              <div className={classes.file}>
                {newPrevious ? (
                  <>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => {
                        setMeetingForm({
                          ...meetingForm,
                          previous: e.target.files[0].name,
                          previousFile: { file: e.target.files[0], fileSrc: URL.createObjectURL(e.target.files[0]) },
                        });
                      }}
                    />
                    <MdOutlineClose
                      onClick={() => {
                        setNewPrevious(false);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className={classes.meetingFile}
                      onClick={() => {
                        setPDF(true);
                        setUrl(`${year}/meetings/${id}/${meetingForm.previous}`);
                      }}
                    >
                      {meetingForm.previous}
                    </div>
                    <MdOutlineChangeCircle
                      onClick={() => {
                        setNewPrevious(true);
                      }}
                    />
                    <AiTwotoneDelete className={classes.delete} onClick={() => {setDeleteFile("議事録")}} />
                  </>
                )}
              </div>
            ) : (
              <input
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  setMeetingForm({
                    ...meetingForm,
                    previous: e.target.files[0].name,
                    previousFile: { file: e.target.files[0], fileSrc: URL.createObjectURL(e.target.files[0]) },
                  });
                }}
              />
            )}
          </div>
          <div className={classes.formGroup}>
            <label>次第</label>
            {+id  && meetingForm.current? (
              <div className={classes.file}>
                {newCurrent ? (
                  <>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => {
                        setMeetingForm({
                          ...meetingForm,
                          current: e.target.files[0].name,
                          currentFile: { file: e.target.files[0], fileSrc: URL.createObjectURL(e.target.files[0]) },
                        });
                      }}
                    />
                    <MdOutlineClose
                      onClick={() => {
                        setNewCurrent(false);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className={classes.meetingFile}
                      onClick={() => {
                        setPDF(true);
                        setUrl(`${year}/meetings/${id}/${meetingForm.current}`);
                      }}
                    >
                      {meetingForm.current}
                    </div>
                    <MdOutlineChangeCircle
                      onClick={() => {
                        setNewCurrent(true);
                      }}
                    />
                    <AiTwotoneDelete className={classes.delete} onClick={() => {setDeleteFile("次第")}} />
                  </>
                )}
              </div>
            ) : (
              <input
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  setMeetingForm({
                    ...meetingForm,
                    current: e.target.files[0].name,
                    currentFile: { file: e.target.files[0], fileSrc: URL.createObjectURL(e.target.files[0]) },
                  });
                }}
              />
            )}
          </div>
          <div className={classes.formGroup}>
            <button className={`${classes.btns} btn greenBtn`}>更新</button>
            <Link to="/admin/meetings">
              <div className={`${classes.btns} btn`}>戻る</div>
            </Link>
          </div>
        </form>
      )}
      {deleteFile ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`${classes.btns} btn greenBtn`}
                onClick={() => {
                  setDeleteFile(false)
                  if (deleteFile === "次第") {
                    setMeetingForm({
                      ...meetingForm,
                      current: "",
                      currentFile: {},
                    });
                  } else {
                    setMeetingForm({
                      ...meetingForm,
                      previous: "",
                      previousFile: {},
                    });
                  }
                }}
              >
                実行
              </div>
              <div
                className={`${classes.btns} btn`}
                onClick={() => {
                  setDeleteFile(false);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
