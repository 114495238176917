import { useContext, useEffect, useState } from "react";
import { Context } from "../../../Store/context";
import classes from "./Meetings.module.scss";
import { AiOutlineFileAdd, AiTwotoneDelete } from "react-icons/ai";
import { TiEdit } from "react-icons/ti";
import { BsArrowDownSquare, BsArrowUpSquare } from "react-icons/bs";
import { FaRegEye, FaRegEyeSlash  } from "react-icons/fa";
import { Link } from "react-router-dom";
import PDFViewer from "../MainDocuments/PDFViewer/PDFViewer";

function Meetings() {
  const {
    currentUser,
    fetchMeetings,
    meetings,
    deleteMeeting,
    deleteDiscussion,
    year,
    setYear,
    editDiscussionOrder,
    fetchReports,
    reports,
    createEditReport,
    editReportOrder,
    editMeetingOrder,
    deleteReport,
  } = useContext(Context);
  useEffect(() => {
    fetchMeetings();
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let years = [];
  if (currentUser.isAdmin) {
    for (let i = 2022; i < +new Date().getFullYear() + 10; i++) {
      years.push(i);
    }
  } else {
    years = currentUser.roles.filter((el) => el.premissions[1] || el.premissions[0]).map((el) => el.year);
  }
  let UserRoles = currentUser.roles.filter((el) => +el.year === +year)[0]?.premissions;
  let cols = ["", "確認事項", "審議事項", "協議事項", "報告事項"];
  const [popup, setPopup] = useState(0);
  const [deletePopup, setDeletePopup] = useState(0);
  const [reportPopup, setReportPopup] = useState(0);
  const [reportDeletePopup, setReportDeletePopup] = useState(0);
  const initialReport = { name: "", fileName: "", fileSrc: "" };
  const [report, setReport] = useState({ ...initialReport });
  const [file, setFile] = useState(null);
  const changeDiscussionOrder = (id, number, phase, direction) => {
    let discussions = meetings
      .filter((el) => el.id === id)[0]
      .discussions.map((el) => {
        if (el.phase === phase) {
          el.number =
            +el.number === +number
              ? el.number - 1 * direction
              : el.number === number - 1 * direction
              ? el.number + 1 * direction
              : el.number;
        }
        return { ...el };
      })
    editDiscussionOrder(id, discussions, year);
  };
  const changeReportOrder = (meeting, report, number, direction) => {
    let ids = [];
    ids.push({ id: report, number: number + direction });
    ids.push({
      id: reports.filter((el) => el.meeting === meeting).filter((el) => el.number === number + direction)[0].id,
      number,
    });
    editReportOrder(ids, year);
  };
  const changeMeetingOrder = (meeting, number, direction) => {
    let ids = [];
    ids.push({ id: meeting, number: number + direction });
    ids.push({
      id: meetings.filter((el) => +el.year === +year).filter((el) => el.number === number + direction)[0].id,
      number,
    });
    editMeetingOrder(ids, year);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setReportPopup(false);
    createEditReport(report, file, year);
    setReport({});
  };
  const [PDF, setPDF] = useState(false);
  const [url, setUrl] = useState(false);
  return (
    <div className="content">
      <div className={classes.tabs}>
        {years.map((currentYear) => (
          <div
            key={currentYear}
            className={`${classes.tab} ${+currentYear === +year ? classes.current : ""} btn`}
            onClick={() => {
              setYear(currentYear);
            }}
          >
            {currentYear}
          </div>
        ))}
      </div>
      {PDF ? (
        <PDFViewer url={url} setPDF={setPDF} />
      ) : (
        <div className={classes.plates}>
          {currentUser.isAdmin || UserRoles[3] ? (
            <Link className={`${classes.btn} btn`} to={"" + year + "/" + 0}>
              <AiOutlineFileAdd />
              追加
            </Link>
          ) : (
            ""
          )}
          <table className={classes.table}>
            <tbody>
              <tr className={`${classes.row} ${classes.title}`}>
                <td className={`${classes.col} ${classes.btnCol}`}></td>
                <td className={`${classes.col} ${classes.btnCol}`}></td>
                {cols.map((col) => (
                  <td key={col} className={`${classes.col}`}>
                    {col}
                  </td>
                ))}
                <td className={`${classes.col} ${classes.btnCol}`}></td>
              </tr>
              {meetings
                .filter((el) => +el.year === +year)
                .sort((a, b) => b.number - a.number)
                .map((meeting, i) => {
                  return (
                    <tr className={`${classes.row} ${classes.odd}`} key={meeting.id}>
                      <td className={`${classes.col}  ${classes.info}`}>
                        <div className={`${classes.number}`}>
                          <span>{meeting.number}</span>
                          <div>
                            {meeting.number > 0 &&
                            meeting.number < meetings.filter((el) => +el.year === +year).length ? (
                              <BsArrowUpSquare
                                className={`${classes.upArrow} ${classes.arrow}`}
                                onClick={() => changeMeetingOrder(+meeting.id, meeting.number, 1)}
                              />
                            ) : null}
                            {meeting.number < meetings.filter((el) => +el.year === +year).length + 1 &&
                            meeting.number > 1 ? (
                              <BsArrowDownSquare
                                className={`${classes.downArrow} ${classes.arrow}`}
                                onClick={() => changeMeetingOrder(+meeting.id, meeting.number, -1)}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className={classes.visible}>{meeting.active ? <FaRegEye /> : <FaRegEyeSlash />}</div>
                      </td>
                      <td className={`${classes.col} ${classes.info}`}>
                        <div>会議名　： {meeting.name}</div>
                        <div>日時　　： {meeting.date}</div>
                        <div>開催場所： {meeting.place}</div>
                      </td>
                      <td className={`${classes.col} ${classes.btnCol}`}>
                        {currentUser.isAdmin || UserRoles[4] ? (
                          <Link to={"" + year + "/" + meeting.id}>
                            <div className={`${classes.btn} btn`}>編集</div>
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className={`${classes.col} ${classes.discussion}`}>
                        <div>
                          {meeting.discussions
                            .filter((el) => el.phase === "確認")
                            .sort((a, b) => a.number - b.number)
                            .map((discussion, i) => (
                              <div className={classes.oneDiscussion} key={discussion.title + i}>
                                <div className={classes.oneDiscussionName}>
                                  <span>({discussion.number})</span>
                                  <span>{discussion.title}</span>
                                </div>
                                <div className={classes.oneDiscussionIcons}>
                                  <div className={classes.oneDiscussionIcon}>
                                    {currentUser.isAdmin || UserRoles[7] ? (
                                      <Link
                                        to={
                                          `/admin/discussions/` +
                                          discussion.discussion +
                                          `?phase=確認&meeting=${meeting.id}`
                                        }
                                      >
                                        <TiEdit />
                                      </Link>
                                    ) : (
                                      ""
                                    )}
                                    {/*currentUser.isAdmin || UserRoles[8] ? (
                                      <AiTwotoneDelete
                                        onClick={() => {
                                          setDeletePopup({
                                            id: meeting.id,
                                            discussion: discussion.discussion,
                                            phase: "確認",
                                          });
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )*/}
                                  </div>
                                  {currentUser.isAdmin || UserRoles[7] ? (
                                    <div className={classes.arrows}>
                                      {discussion.number > 1 ? (
                                        <BsArrowUpSquare
                                          className={`${classes.upArrow} ${classes.arrow}`}
                                          onClick={() =>
                                            changeDiscussionOrder(meeting.id, discussion.number, "確認", 1)
                                          }
                                        />
                                      ) : null}
                                      {discussion.number <
                                      meeting.discussions.filter((el) => el.phase === "確認").length ? (
                                        <BsArrowDownSquare
                                          className={`${classes.downArrow} ${classes.arrow}`}
                                          onClick={() =>
                                            changeDiscussionOrder(meeting.id, discussion.number, "確認", -1)
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                        {currentUser.isAdmin || UserRoles[6] ? (
                          <Link
                            to={`/admin/discussions/` + 0 + `?phase=確認&meeting=${meeting.id}`}
                            className={`${classes.btn} btn`}
                          >
                            <AiOutlineFileAdd />
                            議案追加
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className={`${classes.col} ${classes.discussion}`}>
                        {meeting.discussions
                          .filter((el) => el.phase === "審議")
                          .sort((a, b) => a.number - b.number)
                          .map((discussion, i) => (
                            <div className={classes.oneDiscussion} key={discussion.title + i}>
                              <div className={classes.oneDiscussionName}>
                                <span>({discussion.number})</span>
                                <span>{discussion.title}</span>
                              </div>
                              <div className={classes.oneDiscussionIcons}>
                                <div className={classes.oneDiscussionIcon}>
                                  {currentUser.isAdmin || UserRoles[7] ? (
                                    <Link
                                      to={
                                        `/admin/discussions/` +
                                        discussion.discussion +
                                        `?phase=審議&meeting=${meeting.id}`
                                      }
                                    >
                                      <TiEdit />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                  {/*currentUser.isAdmin || UserRoles[8] ? (
                                    <AiTwotoneDelete
                                      onClick={() => {
                                        setDeletePopup({
                                          id: meeting.id,
                                          discussion: discussion.discussion,
                                          phase: "審議",
                                        });
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )*/}
                                </div>
                                {currentUser.isAdmin || UserRoles[7] ? (
                                  <div className={classes.arrows}>
                                    {discussion.number > 1 ? (
                                      <BsArrowUpSquare
                                        className={`${classes.upArrow} ${classes.arrow}`}
                                        onClick={() => changeDiscussionOrder(meeting.id, discussion.number, "審議", 1)}
                                      />
                                    ) : null}
                                    {discussion.number <
                                    meeting.discussions.filter((el) => el.phase === "審議").length ? (
                                      <BsArrowDownSquare
                                        className={`${classes.downArrow} ${classes.arrow}`}
                                        onClick={() => changeDiscussionOrder(meeting.id, discussion.number, "審議", -1)}
                                      />
                                    ) : null}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                        {currentUser.isAdmin || UserRoles[6] ? (
                          <Link
                            to={`/admin/discussions/` + 0 + `?phase=審議&meeting=${meeting.id}`}
                            className={`${classes.btn} btn`}
                          >
                            <AiOutlineFileAdd />
                            議案追加
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className={`${classes.col} ${classes.discussion}`}>
                        {meeting.discussions
                          .filter((el) => el.phase === "協議")
                          .sort((a, b) => a.number - b.number)
                          .map((discussion, i) => (
                            <div className={classes.oneDiscussion} key={discussion.title + i}>
                              <div className={classes.oneDiscussionName}>
                                <span className={classes.numberSpan}>({discussion.number})</span>
                                <span>{discussion.title}</span>
                              </div>
                              <div className={classes.oneDiscussionIcons}>
                                <div className={classes.oneDiscussionIcon}>
                                  {currentUser.isAdmin || UserRoles[7] ? (
                                    <Link
                                      to={
                                        `/admin/discussions/` +
                                        discussion.discussion +
                                        `?phase=協議&meeting=${meeting.id}`
                                      }
                                    >
                                      <TiEdit />
                                    </Link>
                                  ) : (
                                    ""
                                  )}
                                  {currentUser.isAdmin || UserRoles[8] ? (
                                    <AiTwotoneDelete
                                      onClick={() => {
                                        setDeletePopup({
                                          id: meeting.id,
                                          discussion: discussion.discussion,
                                          phase: "協議",
                                        });
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {currentUser.isAdmin || UserRoles[7] ? (
                                  <div className={classes.arrows}>
                                    {discussion.number > 1 ? (
                                      <BsArrowUpSquare
                                        className={`${classes.upArrow} ${classes.arrow}`}
                                        onClick={() => changeDiscussionOrder(meeting.id, discussion.number, "協議", 1)}
                                      />
                                    ) : null}
                                    {discussion.number <
                                    meeting.discussions.filter((el) => el.phase === "協議").length ? (
                                      <BsArrowDownSquare
                                        className={`${classes.downArrow} ${classes.arrow}`}
                                        onClick={() => changeDiscussionOrder(meeting.id, discussion.number, "協議", -1)}
                                      />
                                    ) : null}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                        {currentUser.isAdmin || UserRoles[6] ? (
                          <Link
                            to={`/admin/discussions/` + 0 + `?phase=協議&meeting=${meeting.id}`}
                            className={`${classes.btn} btn`}
                          >
                            <AiOutlineFileAdd />
                            議案追加
                          </Link>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className={`${classes.col} ${classes.discussion}`}>
                        {/*meeting.discussions
                        .filter((el) => el.phase === "報告")
                        .sort((a, b) => a.number - b.number)
                        .map((discussion) => (
                          <div className={classes.oneDiscussion} key={discussion.title}>
                            <div className={classes.oneDiscussionName}>
                              <span>({discussion.number})</span>
                              <span>{discussion.title}</span>
                            </div>
                            <div className={classes.oneDiscussionIcons}>
                              <div className={classes.oneDiscussionIcon}>
                                <Link
                                  to={
                                    `/admin/discussions/` + discussion.discussion + `?phase=報告&meeting=${meeting.id}`
                                  }
                                >
                                  <TiEdit />
                                </Link>
                                <AiTwotoneDelete
                                  onClick={() => {
                                    setDeletePopup({
                                      id: meeting.id,
                                      discussion: discussion.discussion,
                                      phase: "報告",
                                    });
                                  }}
                                />
                              </div>
                              <div className={classes.arrows}>
                                {discussion.number > 1 ? (
                                  <BsArrowUpSquare
                                    className={`${classes.upArrow} ${classes.arrow}`}
                                    onClick={() => changeDiscussionOrder(meeting.id, discussion.number, "報告", 1)}
                                  />
                                ) : null}
                                {discussion.number < meeting.discussions.filter((el) => el.phase === "報告").length ? (
                                  <BsArrowDownSquare
                                    className={`${classes.downArrow} ${classes.arrow}`}
                                    onClick={() => changeDiscussionOrder(meeting.id, discussion.number, "報告", -1)}
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                                ))*/}
                        {/*<Link
                        to={`/admin/discussions/` + 0 + `?phase=報告&meeting=${meeting.id}`}
                        className={`${classes.btn} btn`}
                      >
                        <AiOutlineFileAdd />
                        議案追加
                              </Link>*/}
                        {reports
                          .filter((el) => +el.meeting === +meeting.id)
                          .sort((a, b) => a.number - b.number)
                          .map((report) => (
                            <div className={classes.oneDiscussion} key={report.id}>
                              <div
                                className={`${report.fileName ? classes.report : ""}`}
                                onClick={() => {
                                  setPDF(true);
                                  setUrl(`${year}/meetings/${report.meeting}/reports/${report.fileName}`);
                                }}
                              >
                                {report.name}
                              </div>
                              <div className={classes.oneDiscussionIcons}>
                                <div className={classes.oneDiscussionIcon}>
                                  {currentUser.isAdmin || UserRoles[7] ? (
                                    <div
                                      onClick={() => {
                                        setReportPopup(report.id);
                                        setReport({ ...report, meeting: meeting.id });
                                      }}
                                    >
                                      <TiEdit />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {currentUser.isAdmin || UserRoles[8] ? (
                                    <AiTwotoneDelete
                                      onClick={() => {
                                        setReportDeletePopup(report.id);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {currentUser.isAdmin || UserRoles[7] ? (
                                  <div className={classes.arrows}>
                                    {report.number > 0 ? (
                                      <BsArrowUpSquare
                                        className={`${classes.upArrow} ${classes.arrow}`}
                                        onClick={() => changeReportOrder(+meeting.id, report.id, report.number, -1)}
                                      />
                                    ) : null}
                                    {report.number < reports.filter((el) => +el.meeting === +meeting.id).length - 1 ? (
                                      <BsArrowDownSquare
                                        className={`${classes.downArrow} ${classes.arrow}`}
                                        onClick={() => changeReportOrder(+meeting.id, report.id, report.number, 1)}
                                      />
                                    ) : null}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ))}
                        {currentUser.isAdmin || UserRoles[6] ? (
                          <div
                            onClick={() => {
                              setReportPopup(true);
                              setReport({ ...report, meeting: meeting.id });
                            }}
                            className={`${classes.btn} btn`}
                          >
                            <AiOutlineFileAdd />
                            議案追加
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                      <td className={`${classes.col} ${classes.btnCol}`}>
                        {currentUser.isAdmin || UserRoles[5] ? (
                          <div className={`${classes.btn} btn redBtn`} onClick={() => setPopup(meeting.id)}>
                            削除
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {popup ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>
              元に戻せなくなります
              <br />
              削除しますか?
            </div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`btn greenBtn`}
                onClick={() => {
                  deleteMeeting(popup, year);
                  setPopup(0);
                }}
              >
                実行
              </div>
              <div
                className={` btn`}
                onClick={() => {
                  setPopup(0);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {deletePopup ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`btn greenBtn`}
                onClick={() => {
                  deleteDiscussion(deletePopup.id, deletePopup.discussion, deletePopup.phase, year);
                  setDeletePopup(0);
                }}
              >
                実行
              </div>
              <div
                className={` btn`}
                onClick={() => {
                  setDeletePopup(0);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {reportPopup ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>報告事項 {reportPopup === true ? "追加" : "編集"}</div>
            <form onSubmit={onSubmit}>
              <div className={classes.formGroup}>
                <label>表示名</label>
                <input
                  required
                  className="input"
                  type="text"
                  value={report?.name || ""}
                  onChange={(e) => {
                    setReport({ ...report, name: e.target.value });
                  }}
                />
              </div>
              {report?.fileName ? (
                <div className={classes.formGroup}>
                  <label style={{ color: "black", marginRight: "5px" }}>現保存ファイル: </label>
                  <div className={classes.fileName}>{report?.fileName}</div>
                </div>
              ) : (
                ""
              )}
              <div className={classes.formGroup}>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => {
                    setReport({ ...report, fileName: e.target.files[0]?.name });
                    setFile({ file: e.target.files[0], fileSrc: URL.createObjectURL(e.target.files[0]) });
                  }}
                />
              </div>
              <div className={`${classes.formGroup} ${classes.btns}`}>
                <button className={`${classes.btn} btn greenBtn`}>更新</button>
                <div
                  className={` btn`}
                  onClick={() => {
                    setReportPopup(0);
                    setReport({ ...initialReport });
                  }}
                >
                  キャンセル
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
      {reportDeletePopup ? (
        <div className={`popupWrapper`}>
          <div className="popup">
            <div className={`popupTitle`}>削除します</div>
            <div className={`popupFormGroup popupFormGroupBtn`}>
              <div
                className={`btn greenBtn`}
                onClick={() => {
                  //console.log(reportDeletePopup)
                  deleteReport(reportDeletePopup, year);
                  setReportDeletePopup(0);
                }}
              >
                実行
              </div>
              <div
                className={` btn`}
                onClick={() => {
                  setReportDeletePopup(0);
                }}
              >
                キャンセル
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Meetings;
