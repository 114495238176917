import parse from "html-react-parser";

function Compare({ prev, current, id }) {
  if (!prev || !current || prev === "undefined" || current === "undefined") {
    return <div>{parse(current || "")}</div>;
  } else {
    /*if (id === 10) {
      console.log(current)
      console.log(prev)
    }*/
    prev = prev.replace(/\n/gi, "");
    current = current.replace(/\n/gi, "");
    prev = prev.replace(/&nbsp;/gi, " ");
    current = current.replace(/&nbsp;/gi, " ");
    prev = prev.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, 'mybr');
    current = current.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, 'mybr');
    let dmp = new window.diff_match_patch();
    var d = dmp.diff_main(prev, current, false);
    /*if (id === 11) {
      console.log(d);
      console.log(dmp);
    }*/
    var ds = dmp.diff_prettyHtml(d);
    ds = ds.replace(/&lt;/gi, "<");
    ds = ds.replace(/&gt;/gi, ">");
    ds = ds.replace(/&nbsp;/gi, " ");
    ds = ds.replace(/nbsp;/gi, " ");
    ds = ds.replace(/&amp;/gi, " ");
    ds = ds.replace(/&para;/gi, "<p>");
    ds = ds.replace(/<br>/gi, "");
    ds = ds.replace(/e6ffe6/gi, "fff");
    ds = ds.replace(/mybr/gi, "<br/>");
    /*if (id === 11) {
      console.log(ds);
    }*/

    return <div>{parse(ds)}</div>;
  }
}

export default Compare;
